import React , { useState,useRef } from 'react'
import './Faq.css'
import award from '../../images/ikaaward.jpeg'


const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);
  
    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="faq-item">
        <div className="faq-question" onClick={toggleOpen} style={{ cursor: 'pointer' }}>
          {question} <div className="faq-gap">{isOpen?<i class="fa-solid fa-circle-chevron-down"></i> : <i class="fa-solid fa-circle-chevron-down fa-flip-vertical"></i>}</div> 
        </div>
        <div
          className="faq-answer"
          ref={contentRef}
          style={{
            maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0px',
            transition: 'max-height 0.5s ease-out',
            overflow: 'hidden'
          }}
        >
          {answer}
        </div>
      </div>
    );
  };
  
  const FAQ = ({ faqs }) => {
    return (
      <div className="faqs-container">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    );
  };
  
  // Example usage:
  const faqData = [
    { question: "Is it a 'Senior Secondary' school?", answer: "Chatterjee Noble School is a CBSE affiliated senior secondary school. " },
    { question: "Is it having hostel facility?", answer: "CNS is having AC hostel facility , both for boys and girls" },
    { question: "Is it CBSE affiliated?", answer: "CNS is a CBSE affiliated school. Affiliation number - 2430356" },
   
    // Add more FAQ items as needed
  ];




const Faq = () => {
  return (
   <div className="faq-container">
      <div className="faq-left-container">
      <img className='faq-image' src={award} alt="" />
      </div>
      <div className="faq-right-container">
        <div className="faq-section-heading">
            Frequently Asked Questions
        </div>
        <FAQ className="faq" faqs={faqData} />
      </div>
   </div>
  )
}

export default Faq