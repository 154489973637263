import './styles/global.css';

import { Route, Routes } from 'react-router-dom';

import Abacus from './pages/Co-Curriculum/Abacus';
import AboutUs from './pages/AboutUs';
import BookList from './pages/Academics/Booklist';
import ChemistryLab from './pages/Facilities/ChemistryLab';
import Club from './pages/Co-Curriculum/Club';
import ContactUs from './pages/ContactUs';
import Curriculum from './pages/Academics/Curriculum';
import Events from './pages/Co-Curriculum/Events';
import Examination from './pages/Academics/Examination';
import Faculty from './pages/Administration/Faculty';
import FeepaymentRules from './pages/Administration/FeepaymentRules';
import HomePage from './pages/HomePage';
import HomeWork from './pages/Academics/Homework';
import House from './pages/Co-Curriculum/House';
import IndoorAndOutdoorGames from './pages/Facilities/IndoorAndOutdoorGames';
import Infirmary from './pages/Facilities/Infirmary';
import Infrastructure from './pages/Infrastructure';
import Karate from './pages/Co-Curriculum/Karate';
import LanguageLab from './pages/Facilities/LanguageLab';
import Library from './pages/Facilities/Library';
import LifeSkills from './pages/Co-Curriculum/LifeSkills';
import Management from './pages/Administration/Management';
import MathLab from './pages/Facilities/MathLab';
import PhysicsLab from './pages/Facilities/PhysicsLab';
import Procedure from './pages/Admission/Procedure';
import React from 'react';
import RulesAndRegulations from './pages/Administration/RulesAndRegulations';
import Schedule from './pages/Admission/Schedule';
import SchoolCalendar from './pages/SchoolCalendar';
import SexualHarassmentComitee from './pages/Administration/SexualHarassmentComitee';
import SmartRoom from './pages/Facilities/SmartRoom';
import SocialScienceLab from './pages/Facilities/SocialScienceLab';
import SoftSkills from './pages/Co-Curriculum/SoftSkills';
import TakeAdmission from './pages/Admission/TakeAdmission';
import Trust from './pages/Administration/Trust';
import VisionMission from './pages/VisionMission';
import Disclosure from './pages/Disclosure';
import RecognitionCertificate from './pages/pdfPages/RecognitionCertificate';
import Gallery from './pages/Gallery';
import PrivecyPolicy from './pages/PrivecyPolicy';
import Ebooks from './pages/Academics/Ebooks';
import Scholarship from './pages/Academics/Scholarship';
import CbseSports from './pages/CbseSports/CbseSports';
import NewTopNavbar from './components/NewTopNavbar/NewTopNavbar';
import NewFooter from './components/NewFooter/NewFooter';
import ExamCenter from './pages/ExamCenter/ExamCenter';
import CLassVII from './pages/ExamCenter/CLassVII';
import ClassVIII from './pages/ExamCenter/ClassVIII';
import Cbsegames from './pages/ExamCenter/Cbsegames';
import NewGallery from './pages/NewGallery/NewGallery';
const App = () => {
  return (
    <>
      {/* <Navbar /> */}
      <NewTopNavbar />
      <Routes>
        <Route path='/Privery_policy' element={<PrivecyPolicy />} />
        {/* privecy policy is at the top  */}
        <Route path='/' element={<HomePage />} />
        <Route path='/AboutUs' element={<AboutUs />} />
        <Route path='/Infrastructure' element={<Infrastructure />} />
        <Route path='/SchoolCalendar' element={<SchoolCalendar />} />
        <Route path='/Contact Us' element={<ContactUs />} />
        <Route path='/Vision&Mission' element={<VisionMission />} />
        {/* routes for Academics section starts here */}
        <Route path='/Curriculum' element={<Curriculum />} />
        <Route path='/Examination' element={<Examination />} />
        <Route path='/Homework' element={<HomeWork />} />
        <Route path='/Booklist' element={<BookList />} />
        <Route path='/ebooks' element={<Ebooks />} />
        <Route path='/Scholarship' element={<Scholarship />} />
        {/* <Route path="/ebooks" element={()=>{
          window,location.href = "https://ndl.iitkgp.ac.in/";
          return null;
        }} */}
        {/* Routes for Administration section starts here  */}
        <Route path='/Trust' element={<Trust />} />
        <Route path='/Management' element={<Management />} />
        <Route path='/Faculty' element={<Faculty />} />
        <Route path='/SexualHarassmentCommittee' element={<SexualHarassmentComitee />} />
        <Route path='/FeePaymentRules' element={<FeepaymentRules />} />
        <Route path='/Rules&Regulations' element={<RulesAndRegulations />} />
        {/* Routes for Admission section starts here */}
        <Route path='/Procedure' element={<Procedure />} />
        <Route path='/Schedule' element={<Schedule />} />
        <Route path='/TakeAdmission' element={<TakeAdmission />} />
        {/* Routes for Co-Curriculum section starts */}
        <Route path='/SoftSkills' element={<SoftSkills />} />
        <Route path='/LifeSkills' element={<LifeSkills />} />
        <Route path='/Karate' element={<Karate />} />
        <Route path='/Abacus' element={<Abacus />} />
        <Route path='/House' element={<House />} />
        <Route path='/Club' element={<Club />} />
        <Route path='/Events' element={<Events />} />
        {/* Routes for Facilites section starts here */}
        <Route path='/Library' element={<Library />} />
        <Route path='/ChemistryLab' element={<ChemistryLab />} />
        <Route path='/PhysicsLab' element={<PhysicsLab />} />
        <Route path='/SocialScienceLab' element={<SocialScienceLab />} />
        <Route path='/MathLab' element={<MathLab />} />
        <Route path='/LanguageLab' element={<LanguageLab />} />
        <Route path='/SmartRoom' element={<SmartRoom />} />
        <Route path='/Infirmary' element={<Infirmary />} />
        <Route path='/Indoor&OutdoorGames' element={<IndoorAndOutdoorGames />} />

        <Route path='/Disclosure' element={<Disclosure />} />
        <Route path='/Gallery' element={<Gallery />} />
        {/* pdf paths starts here */}
        <Route path='/docs/pdf/SocityRegistration.pdf' element={<RecognitionCertificate />} />
        {/* extra routes  */}
        <Route path='/cbse-sports' element={<CbseSports />} />
        

        {/* exam paths  */}
        <Route path='/exam-center' element={<ExamCenter/>}/>
        <Route path='/exam-vii' element={<CLassVII/>}/>
        <Route path='/exam-viii' element={<ClassVIII/>}/>
        <Route path='/cbse-games' element={<Cbsegames/>}/>
        <Route path='/cns-gallery' element={<NewGallery/>}/>
      </Routes>
      <NewFooter />
     {/* <Footer/> */}
    </>
  );
};

export default App;
