import React from 'react'
import AdmissionForm from '../../components/AdmissionForm';
import HeroImage from '../../components/HeroImage';
const TakeAdmission =()=> {
    return (
        <div>
           <HeroImage/>
           <AdmissionForm/> 
        </div>
    )
}
export default TakeAdmission;
