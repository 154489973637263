import "../styles/ArtGallery.css";

import { useState } from "react";

const ArtGallery = () => {
  const [img_url, set_img_url] = useState("./images/school_web.jpg");
  const [img_id, setImageId] = useState(0);
  const images = [
    "./images/D.jpg",
    "./images/DSS.jpg",
    "./images/DSSSS.jpg",
    "./images/DSSSSJJ.Jpg",
    "./images/h1.jpeg",
    "./images/h2.jpeg",
    "./images/h3.jpeg",
    "./images/h4.jpeg",
    "./images/h5.jpeg",
    "./images/h6.jpeg",

  ];
  const getNextImage = () => {
    const totalImages = images.length;
    if (img_id + 1 == totalImages) {
      setImageId(0);
      set_img_url(images[0]);
    } else {
      setImageId(img_id + 1);
      set_img_url(images[img_id + 1]);
    }
  };
  const getPreviousImage = () => {
    const totalImages = images.length;
    if (img_id > 0) {
      setImageId(img_id - 1);
      set_img_url(images[img_id - 1]);
    } else {
      setImageId(totalImages - 1);
      set_img_url(images[totalImages - 1]);
    }
  };
  return (
    <>
      <div className="artgallery">
        <div className="art_heading">
          <div className="row">
            <div className="col-4 px-0">
              <div className="art_text">Gallery</div>
            </div>

            <div className="col-8 d-flex">
              <div className="straight_line"></div>
              <div className="btn-container">
                <div className="btn btn-primary" onClick={getPreviousImage}>
                  <i className="fas fa-arrow-circle-left"></i>
                </div>
                <div className="btn btn-primary ms-1" onClick={getNextImage}>
                  <i className="fas fa-arrow-circle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="image_slider">
          <img src={img_url} alt="image" className="art_img" />
        </div>
      </div>
    </>
  );
};
export default ArtGallery;
