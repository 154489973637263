import "../styles/Heading.css";

import React from "react";

const Heading = (props) => {
  return (
    <div className="heading_section_container">
      <dv className="row">
        <div className={props.colHeading}>
          <div className="heading">{props.title}</div>
        </div>
        <div className={props.colDescription}>
          <div className="straight_line_heading"></div>
        </div>
      </dv>

      <div className="row py-2 px-3">
        <div
          className="sub_heading padding_x_zero"
          dangerouslySetInnerHTML={{ __html: props.description }}
        />
      </div>
    </div>
  );
};

export default Heading;
