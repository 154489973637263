import axios from "axios";
import { baseUrl } from ".";

export const getPageData = async (pageName) => {
  try {
    const response = await axios.get(
      `${baseUrl}items/pages?filter[page_name]=${pageName}`
    );

    return response?.data?.data[0] || null;
  } catch (error) {
    console.log(
      "🚀 ~ file: page.resolver.js ~ line 12 ~ getPageData ~ error",
      error
    );
    return null;
  }
};
