import React from 'react'
import Heading from "../../components/Heading";
import HeroImage from "../../components/HeroImage";
import ChemistryLabPic from "../../images/chem_final_1.webp";
import Lib1 from '../../images/library1.jpeg';
import Lib2 from '../../images/library2.jpeg';
import Lib3 from '../../images/library3.jpeg';
import Lib4 from '../../images/library4.jpeg';
import "../../styles/Facilites/ChemistryLab.css";
const Library = () =>{
    const schoolLibrary = "Library is a treasure trove of books, where students embark on literary adventures and expand their horizons. With a vast collection of fiction, non-fiction, and reference materials, our library nurtures a love for reading and lifelong learning. Whether you're seeking academic resources or exploring new worlds through stories, our friendly librarians are here to assist you. Discover a quiet space to study, engage in research, or simply lose yourself in the magic of literature. Open the doors to endless possibilities at our vibrant school library!";
    return (
      <>
        <HeroImage />
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col">
              <Heading
                title={"Library"}
                description={""}
                colHeading={"col-lg-3 col-md-3"}
                colDescription={"col-lg-9 col-md-9"}
              />
              <div className="aboutText">{schoolLibrary}</div>
            </div>
            <div className="col-lg-4 col-md-4 col">
              <img
                src={Lib1}
                width="400px"
                heigh="400px"
                className="ChemistryLabImage"
              />
            </div>
          </div>

          <div className="container houssesContainer">
        <div className="row">
          {/* this card is for houseses */}
          <div className="col-lg-4 col-md-6 col-12">
            <div className="houseCard">
              <div className="img w-100">
                <img
                  src={Lib2}
                  width="300"
                  heigh="300"
                  className="img-fluid w-100"
                  alt="cns-chem-lab"
                />
              </div>
              
            </div>
          </div>
          {/* This card ends here */}
          {/* this card is for houseses */}
          <div className="col-lg-4 col-md-6 col-12">
            <div className="houseCard">
              <div className="img w-100">
                <img
                  src={Lib3}
                  width="300"
                  heigh="300"
                  className="img-fluid w-100"
                  alt="cns-chem-lab"
                />
              </div>
              
            </div>
          </div>
          {/* This card ends here */}

          {/* this card is for houseses */}
          <div className="col-lg-4 col-md-6 col-12">
            <div className="houseCard">
              <div className="img w-100">
                <img
                  src={Lib4}
                  width="300"
                  heigh="300"
                  className="img-fluid w-100"
                  alt="cns-chem-lab"
                />
              </div>
              
            </div>
          </div>
          {/* This card ends here */}

         
          
        </div>
      </div>  
        </div>
      </>
    );
}

export default Library;
