import '../styles/Navbar.css';
import { Link } from 'react-router-dom';
import NavigationLink from '../components/NavigationLink';
import React from 'react';
import allLinks from '../data/navbar.json';
import logo from '../images/logo.png';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const onClickNavItemHandler =
    (id, context = '') =>
    () => {
      const otherNavItems = document.querySelectorAll(`input[data-id="${context}"]:not(#${id})`);

      for (const navItem of otherNavItems) {
        navItem.checked = false;
      }
    };

  const toggleMobileMenu = () => {
    const listElem = document.querySelector('div.mobile-menu-list');
    const showClass = 'd-block';
    const hideClass = 'd-none';
    if (listElem.classList.contains(hideClass)) {
      listElem.classList.remove(hideClass);
      listElem.classList.add(showClass);
    } else {
      listElem.classList.remove(showClass);
      listElem.classList.add(hideClass);
    }
    setIsMobileMenuOpen(() => !listElem.classList.contains(hideClass));
  };

  return (
    <div className='total-nav-caonatiner position-relative'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 col-xl-4'>
            <div className='row position-relative'>
              <div className='col-2 col-lg-3'>
                <a href='/' title='Chatterjee Noble School'>
                  <img src={logo} alt='img' className='navbar-cns-logo' />
                </a>
              </div>
              <div className='col-10 col-lg-9 !tw-pl-0 -tw-ml-6 md:tw-ml-0'>
                <div className='school-name'>Chatterjee Noble School</div>
                <div className='school-affiliation'>
                  <p className='tw-mb-3'>Affiliated to CBSE Delhi</p>
                </div>
              </div>
              <button
                aria-label='menu'
                className='position-absolute d-lg-none mobile-menu-btn'
                onClick={toggleMobileMenu}>
                <i className='fa-solid fa-bars' />
              </button>
            </div>
          </div>
          {/* This is the section for all the navigation links */}
          <div className='col-lg-7 col-xl-8'>
            <div className='d-none d-lg-block'>
              <div className='row'>
                {allLinks.map(({ title, links, id }) => (
                  <div className='col-lg-4 col-xl-2 navbar-items py-3 px-xl-0' key={id}>
                    <input type='checkbox' id={id} data-id='nav-item-cb' />
                    <label
                      htmlFor={id}
                      className='tw-ease-out tw-duration-300 nav_menu_btn tw-font-medium'
                      onClick={onClickNavItemHandler(id, 'nav-item-cb')}>
                      <span>{title}</span>
                      <i className='fas fa-caret-down' />
                    </label>
                    <label
                      htmlFor={id}
                      className='dropdown_list_toggler tw-z-[9] tw-bg-black tw-opacity-5 tw-fixed tw-top-0 tw-bottom-0 tw-left-0 tw-right-0'
                    />
                    <div className='dropdown_list'>
                      <NavigationLink nav={links} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className='tw-flex tw-flex-wrap tw-justify-center tw-space-x-6 lg:tw-justify-end'>
              <div className='nav-top-text top-nav-link-item'>
                <Link
                  to={'/pdf/ncertbooklists.pdf'}
                  target='blank'
                  className='nav-top-right-btn tw-text-sm md:tw-text-base tw-flex-shrink-0'>
                  Book Lists
                </Link>
              </div>
              <div className='nav-top-text top-nav-link-item'>
                <a
                  href='https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=950023'
                  className='nav-top-right-btn tw-text-sm md:tw-text-base'>
                  Pay Fees
                </a>
              </div>
              <div className='nav-top-text top-nav-link-item'>
                <Link
                  to={'/pdf/mandatory-public-disclosure.pdf'}
                  target='blank'
                  className='nav-top-right-btn tw-text-sm md:tw-text-base'>
                  Mandatory Public Disclosure
                </Link>
              </div>
            </div> */}
          </div>
          {/* This is the end of all the navigation links */}
        </div>
      </div>
      {isMobileMenuOpen && (
        <button onClick={toggleMobileMenu} className='tw-fixed tw-inset-0 tw-bg-black tw-opacity-5 tw-z-10' />
      )}
      <div className='mobile-menu-list d-none tw-relative tw-z-20'>
        {allLinks.map(({ title, links, id: oldId }) => {
          const id = `${oldId}-mobile`;

          return (
            <div className='col-12 mobile-list-item' key={id}>
              <input type='checkbox' id={id} data-id='mobile-nav-item-cb' />
              <label htmlFor={id} className='' onClick={onClickNavItemHandler(id, 'mobile-nav-item-cb')}>
                <span>{title}</span>
                <i className='fas fa-caret-down'></i>
              </label>
              <div className='mobile-menu-link-list'>
                <NavigationLink nav={links} onClick={toggleMobileMenu} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Navbar;
