import React, { useState } from 'react';
import './ExamCenter.css';

const list = [
  {
    title: 'Class VI 3rd Lang bengali',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScnI91SW-seqKyi-HDCEqjTqI1odBMLybKO4NjUMYKpBP2HWQ/viewform?embedded=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class V  third language bengali',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeRrQAWWuWxZJFz8Q7Hmn-iLCOSF9_oKaKpVDiLrveXWOCybg/viewform?embedded=true" width="640" height="12412" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  {
    title: 'Class VI  third language hindi',
    iframe: `<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd95ycM1qyUXm9tHY1e_JBLB4QBvPPLX1iEZRPGeE7-DakX_g/viewform?embedded=true" width="640" height="10626" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
    active: true,
  },
  
];

const ExamCenter = () => {
  const [showButtons, setShowButtons] = useState(true);
  const [openTestIndex, setOpenTestIndex] = useState('');

  return (
    <div className='exam-center-container'>
      {showButtons && (
        <div className='tw-container tw-mx-auto tw-h-screen tw-mt-10'>
          {list
            .filter(({ active }) => active)
            .map((item) => (
              <button
                key={item.title}
                className='hover:tw-ease-out tw-duration-300 tw-w-full tw-py-3 hover:tw-text-[#730e1c] tw-font-bold tw-rounded-lg tw-text-white tw-mb-5 tw-border-[#730e1c] tw-bg-[#730e1c] hover:tw-bg-white tw-border-2'
                onClick={() => {
                  setOpenTestIndex(item.title);
                  setShowButtons(false);
                }}>
                {item.title}
              </button>
            ))}
        </div>
      )}

      <div className='exam-questions'>
        {(() => {
          const activeItem = list.find((list) => list.active && list.title === openTestIndex);

          if (activeItem) {
            return (
              <div
                className='toggle-section'
                dangerouslySetInnerHTML={{
                  __html: activeItem.iframe,
                }}></div>
            );
          }

          return null;
        })()}
      </div>
    </div>
  );
};

export default ExamCenter;
