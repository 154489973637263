import React from 'react';
import './NewTopNavbar.css';
import Navbar from '../Navbar';

const NewTopNavbar = () => {
  return (
    <>
      <Navbar />
      <div className='top_nav_bar_container'>
        <div className='top_nav_bar_left_container'>
          <p id='top_nav_bar_left_text'>
            {/* Pay Fees Online -<b>
                <a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=950023" className="nav-top-right-btn">
                    Pay Now
                  </a>
                </b> */}
            Be a part of the BEST CBSE school in Nadia
          </p>
        </div>
        <div id='triangle-bottomright'></div>
        <div className='top_nav_bar_right_container'>
          <div id='top_nav_bar_right_text'>
            AFFILIATION NUMBER: <b>2430356</b> SCHOOL CODE: <b>16119</b>
          </div>
          <div className='col-md-3 col-xl-2 nav-top-text top-nav-link-item'>
            <i class='fas fa-phone-alt mx-2'></i>
            <a href='tel:+919064792605'>9064792605</a>
          </div>

          <div className='col-md-3 col-xl-3 nav-top-text top-nav-link-item'>
            <i class='far fa-envelope mx-2 mt-1'></i>
            <a href='mailto:cnsbprincipal@gmail.com'>cnsbprincipal@gmail.com</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTopNavbar;
