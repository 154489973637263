import React from "react";
import HeroImage from "../components/HeroImage";
import ArtGallery from "../components/ArtGallery";
import Heading from "../components/Heading";
import "../styles/VisionMission.css";
const VisionMission = () => {
  const description = "";
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12">
            <Heading
              title="Vision & Mission"
              description={description}
              colHeading={"col-lg-4"}
              colDescription={"col-lg-8"}
            />
            {/* container starts here */}
            <div className="vmcontainer">
              <div className="vmImage_container">
                <img src={"./images/vision.png"} className="vmImage" />
              </div>
              <div className="vmdescription">
                <div className="vmTitle">Vision</div>
                Our vision is to promote overall development of students and to
                impart education in a scientifically designed manner thereby
                addressing to the academic and cultural requirements of a
                dynamic multilingual population of the country. We believe that
                the fundamental role of education is to generate real
                understanding among students and to develop leadership qualities
                combined with a sense of competitive attitude within them. We
                aim to provide optimum growth for students applying highly
                sophisticated teaching methodologies in a positive, safe, green
                and stimulating learning environment. Thus, the sole purpose of
                this institution is to mould children in the best possible way
                so as to assist them to reach their full potential as a human
                being.
              </div>
            </div>
            {/* container ends here */}
            {/* container starts here */}
            <div className="vmcontainer">
              <div className="vmImage_container">
                <img src={"./images/mission.png"} className="vmImage" />
              </div>
              <div className="vmdescription">
                <div className="vmTitle">Mission</div>
                Ensuring favourable learning environment to students serves as a
                key factor to promote their holistic development. Therefore, our
                mission is to identify and acknowledge the unique behavioural
                traits of each and every student and address to their specific
                needs. We seek to develop integrity, collaboration and a sense
                of fellow-feeling based on mutual respect among students there
                by preparing them to encounter future challenges in a positive
                way and to become mentally, physically and socially adaptive to
                any situation.
              </div>
            </div>
            {/* container ends here */}
            {/* container starts here */}
            <div className="vmcontainer">
              <div className="vmImage_container">
                <img src={"./images/motto.png"} className="vmImage" />
              </div>
              <div className="vmdescription">
                <div className="vmTitle">Motto</div>
                We are open to all students irrespective of caste and creed,
                they are accepted and cherished as they are and are helped to
                grow in their cultural, social and religious traditions. We
                accept, defend and promote the rights of children and the
                awakening of social consciousness as our educational
                responsibility, functioning with total credibility and
                transparency.
              </div>
            </div>
            {/* container ends here */}
            {/* container starts here */}
            <div className="vmcontainer">
              <div className="vmImage_container">
                <img src={"./images/values.png"} className="vmImage" />
              </div>
              <div className="vmdescription">
                <div className="vmTitle">Values</div>
                “Excellence and Service” is the motto of Chatterjee Noble
                School. Robust quality assurance procedures which are in place
                offer the students opportunities to be equipped with the skills
                to take ownership of their own learning and develop resilience
                for a future yet not yet known. With a commitment to creativity
                across the curriculum with the highest academic standards, the
                school aims to provide an environment where students learn well,
                behave well and respect others. The school promotes inclusive
                secular values as well.
              </div>
            </div>
            {/* container ends here */}
          </div>
          <div className="col-lg-4 col-md-4 col-12">
            <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};
export default VisionMission;
