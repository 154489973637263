import React from "react";
import HeroImage from "../../components/HeroImage";
import ArtGallery from "../../components/ArtGallery";
import TableComponent from "../../components/TableComponent";
import Heading from "../../components/Heading";
const SexualHarassmentComitee = () => {
  const description = "";
  const data = {
    headings: ["SL.No.","Name","Designation in Commitee","Occupation","Phone Number",],
    rows: [["1","Mrs.Sumana Chatterjee","Chairperson","Business,Birnagar,Nadia","8918860539",],
    ["2","Mrs.Nilnjana Chokraborty","Academic Director","Business,Birnagar,Nadia","7044076005"],
    ["3","Mrs.Gunja Das","Principal","Principal,Birnagar,Nadia","9432341829"],
    ["4","Mrs.Lupa Haldar Biswas","Member"," Librarian","9832043631"],
    ["5","Mrs.Lipi Saha	Member","Teacher","Ckids,Birnagar","8927706941"],
    ["6","Miss Taniya Dutta Choudhuri","Member","Teacher Cchamps","9382687464"],
    ["7","Mr.Anupam Kundu","Parents Representative","Business,Railbazar,Birnagar","7478269819"],
    ["8","Mr.Manoj Ghosh","Teacher's Representative","Teacher,Radhanagar,Kalinarayanpur","8637551312"],],
  };
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-12">
            <Heading title="Sexual Harasssment Comitee" description={description} colHeading={"col-lg-6"} colDescription={"col-lg-6"}/>
            <TableComponent tableData={data} />
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
          <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};

export default SexualHarassmentComitee;


