import React from 'react';
import '../styles/TableComponent.css';

function TableComponent({ rows = [], loading, headings = [] }) {
  return (
    <div className='table_container tw-w-full'>
      <table className='tableComponent tw-w-full'>
        <tr className='table_component_tr'>
          {headings.map((cell) => {
            return <th className='table_component_th'>{cell}</th>;
          })}
        </tr>
        {loading ? (
          <div className='tw-w-full'>
            <div className='tw-py-8 tw-text-center'>Loading...</div>
          </div>
        ) : null}
        {rows.map((row) => {
          return (
            <tr className='table_component_tr'>
              {row.map((cell) => (
                <td className='table_component_td'>{cell}</td>
              ))}
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default TableComponent;
