import React, { useState } from "react";
import "../../src/styles/InfraFilter.css";
const InfraFilter = (props) => {
  const handel_infra_button_click = (Infra_type) => {
    var updatedBtnClass = ["","","",""]
    if(Infra_type==="All")
    {
      updatedBtnClass[0]="filter_botton_active";
      updatedBtnClass[1]="filter_botton";
      updatedBtnClass[2]="filter_botton";
      updatedBtnClass[3]="filter_botton";
      setFilterBtnClass(updatedBtnClass);
    }else if(Infra_type==="Class Room"){
      updatedBtnClass[0]="filter_botton";
      updatedBtnClass[1]="filter_botton_active";
      updatedBtnClass[2]="filter_botton";
      updatedBtnClass[3]="filter_botton";
      setFilterBtnClass(updatedBtnClass);
    }else if(Infra_type==="Library"){
      updatedBtnClass[0]="filter_botton";
      updatedBtnClass[1]="filter_botton";
      updatedBtnClass[2]="filter_botton_active";
      updatedBtnClass[3]="filter_botton";
      setFilterBtnClass(updatedBtnClass);
    }else {
      updatedBtnClass[0]="filter_botton";
      updatedBtnClass[1]="filter_botton";
      updatedBtnClass[2]="filter_botton";
      updatedBtnClass[3]="filter_botton_active";
      setFilterBtnClass(updatedBtnClass);
    }
    
    if (Infra_type === "All") {
      props.getFilterdData(props.school_infrastructures);
    } else {
      const filterdInfra = props.school_infrastructures.filter(
        (currentElement) => {
          return currentElement.type === Infra_type;
        }
      );
      props.getFilterdData(filterdInfra);
    }
  };

  const allBtnClasses = [
    "filter_botton",
    "filter_botton",
    "filter_botton",
    "filter_botton",
  ]
  const [filterBtnClass,setFilterBtnClass] = useState(allBtnClasses)
  return (
    <div className="infra_filter_container">
      <div className="innter_filter_container">
        <div
          className={filterBtnClass[0]}
          onClick={() => handel_infra_button_click("All")}
        >
          All
        </div>
        <div
          className={filterBtnClass[1]}
          onClick={() => handel_infra_button_click("Class Room")}
        >
          Classroom
        </div>
        <div
          className={filterBtnClass[2]}
          onClick={() => handel_infra_button_click("Library")}
        >
          Library
        </div>
        <div
          className={filterBtnClass[3]}
          onClick={() => handel_infra_button_click("Laboratory")}
        >
          Laboratory
        </div>
      </div>
    </div>
  );
};

export default InfraFilter;
