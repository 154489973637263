import React ,{useState} from "react";
import HeroImage from "../components/HeroImage";
import InfraFilter from "../components/InfraFilter";
import "../styles/AboutUS/Infrastructure.css";
import SchoolImg from "../images/Chatterjee Noble School.jpg";
import SchoolImage from '../images/school_web.jpg'
import Bathroom from '../images/Bathroom.jpg'
import BiologyLab from '../images/Biology Lab.jpg'
import ChemistryLab from '../images/Chemistry Lab.jpg'
import CompositeLab from '../images/Composite Lab.jpg'
import Field from '../images/Field.jpg'
import LibraryImage from '../images/Library.jpg'
const Infrastructure = () => {
  const school_infrastructures = [
  {type:"Laboratory",image:Bathroom,name:""},
  {type:"Library",image:BiologyLab,name:""},
  {type:"Laboratory",image:ChemistryLab,name:""},
  {type:"Laboratory",image:CompositeLab,name:""},
  // {type:"Library",image:Field,name:""},
  {type:"Laboratory",image:LibraryImage,name:""},

]
const [infras,setInfras] = useState(school_infrastructures)
const getFilterdData = (updatedInfras) =>{
  setInfras(updatedInfras);
}
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <InfraFilter getFilterdData={getFilterdData} school_infrastructures={school_infrastructures}/>
          {/* I have commented out the school infra filter */}
        </div>
        <div className="infra_container">
          <div className="row">

           {infras.map(infra=>(
             <div className="col-lg-3 col-md-6 col-12">
             <div className="Infra_img_container">
               <img
                 src={infra.image}
                 alt="schoolImg"
                 width="250px"
                 height="250px"
               />
               
             </div>
           </div>
           ))}

          </div>
        </div>
      </div>
    </>
  );
};
export default Infrastructure;
