import React from "react";
import HeroImage from "../../components/HeroImage";
import ArtGallery from "../../components/ArtGallery";
import TableComponent from "../../components/TableComponent";
import Heading from "../../components/Heading";
import "../../styles/Administration/Trust.css";
const Trust = () => {
  const data = {
    headings: [
      "SL No",
      "Name",
      "Designation In the Commitee",
      "Ocupation",
      "Telephone Number",
    ],
    rows: [
      [
        "1",
        "Mr.Soumyo Deep Banerjee",
        "Chairman",
        "Advocate kolkata High Court,Kolkata",
        "830420018",
      ],
      [
        "2",
        "Mr.Pratim Chatterjee",
        "Secretary",
        "Advocate kolkata High court,kolkata",
        "9836831662",
      ],
      [
        "3",
        "Mr.Debaprasad Chakraboty",
        "Treasurer",
        "Service, Sodepur,24 prgns",
        "9836505112",
      ],
      ["4", "Mr.Sayan Bhador", "Member", "Educationist", "9433433649"],
      ["5", "Dr.Sajal Ganguli", "Member", "Doctor", "933748516"],
      [
        "6",
        "Mrs.Nilanjana Chakraborty",
        "Member Business",
        "Birnagar, Nadia",
        "7044076005",
      ],
      ["7", "Miss.Pritha Bhador", "Member", "Educationst", "0332422628"],
      [
        "8",
        "Mrs.Manjari Bannerjee",
        "Member Advocate kolkata high court",
        "kolkata",
        "8617282496",
      ],
      [
        "9",
        "Mrs.Sumana Chatterjee",
        "Member Business",
        "Birnagar, Nadia",
        "8918860539",
      ],
      [
        "10",
        "Mrs.Nanada Chakraborty",
        "Member Business",
        "Sodepur, 24 prgns(N)",
        "9674578715",
      ],
    ],
  };
  const description =
    "Chatterjee Welfare Trust created on 22nd December day of 2015 having a registered office at Uttarpara, Postoffice-Birnagar ,Dist-Nadia, Pin-741127 with an objective to construct, establish schools ,colleges and other educational institutions for the benefit of the public. The main idea behind the formation of this trust is to do charitable work in the field of education.";
  return (
    <>
      <HeroImage />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-12">
            <Heading
              title="Trust"
              description={description}
              colHeading={"col-lg-2"}
              colDescription={"col-lg-10"}
            />
            <div className="tableScroll">
              <TableComponent tableData={data} />
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12">
            <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};

export default Trust;
