import React from "react";
import HeroImage from "../../components/HeroImage";
import NoticeBoard from "../../components/NoticeBoard";
import ArtGallery from "../../components/ArtGallery";
import TitleComponent from "../../components/TitleComponent";
import Heading from "../../components/Heading";
import Capsule from "../../components/Capsule";
const Procedure = () => {
  const headingDescription =
    "Minimum age of a child for admission to Ckids, Cchamps and Cstars are 3+, 6+ and 12+ respectively. The general public is informed through advertisement/ notification in the newspapers / school notice board / school website about the commencement of the admission process. Registration forms are sold online through the School website against a stipulated amount Or offline from our school office. The school shortlists candidates as per its own criteria and parents of such short – listed children will be required to come to the school along with their wards for an interaction with the Principal.";
  const rules = [
    "Registration does not imply admission it is subject to the availability of seat and fulfilling of admission criteria.",
    "Parents may collect prospectus and registration form from school office or download it from the school website or use the link provided below.",
    "The duly filled in registration form to be submitted at the school office.",
    "After registration the child is required to appear for an interview admission test.",
    "Schools fees deposit will not be refunded.",
    "The mode of payment is in cheque/cash/debit card/credit card/online transfer",
    "You can also email us at cnsbadmission@gmail.com Or call us at 90647 92605 for further information on the admission process.",
    "The parents are required to fill up the admission form very carefully before submission with the admission form of Rs.500 at school office during office hours.",
  ];
  const documents = [
      "Photocopy of birth certificate",
      "Photocopy of address proof.",
      "Photocopy of the father and of the child and parents.",
      "Passport size photo of child (two copy)",
      "Passport size photo of parents (one copy)",
      "Passport size photo of local guardian ,if any (one copy)",
      "Original transfer certificate and photocopy of marksheet of previous school",
      "Photocopy of communities certificate (If Any)",
      "Parents income/service salary certificate",
      "Photo copy of medical report and certificate for blood group and other allergies et cetera",
      "Child Fitness certificate."
  ]
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-8">
            <Heading
              colHeading={"col-lg-4"}
              colDescription={"col-lg-8"}
              description={headingDescription}
              title={"Admission Procedure"}
            />
            {rules.map((rule) => (
                  <Capsule name={rule} />
                ))}
                <br />
                <br />
            <TitleComponent data={"Documents Required for Admission"}/>
            {documents.map((document) => (
                  <Capsule name={document} />
                ))}
          </div>
          <div className="col-md-4 col-lg-4">
            <NoticeBoard />
            <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};

export default Procedure;
