import "../styles/AdmissionForm.css";

import { submitAdmissionContact } from "../services/homepage.service";
import { useState } from "react";

const validateStudentContactDetails = (state) => {
  let error = "";

  if (state?.student_name?.length < 5) {
    error += `<p>Enter full student name. Student name should be greater then 5character.</p>`;
  }
  if (state?.age < 1) {
    error += `<p>Enter a valid student age. Student age should be greater then 1 year.</p>`;
  }
  if (state?.contact_number?.length !== 10) {
    error += `<p></p>Enter a valid contact number. Contact number should have 10 digit.</p>`;
  }

  return error;
};

const AdmissionForm = () => {
  const initialFormState = {
    student_name: "",
    age: null,
    previous_school: "",
    contact_number: "",
    address: "",
  };

  const [state, setState] = useState({ ...initialFormState });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handelSubmit = async (event) => {
    setIsLoading(() => true);
    setErrorMessage(() => "");
    setSuccessMessage(() => "");
    event?.preventDefault();

    const error = validateStudentContactDetails(state);

    if (error === "") {
      const response = await submitAdmissionContact({
        student_name: state.student_name,
        age: state.age,
        previous_school: state.previous_school,
        contact_number: state.contact_number,
        address: state.address,
      });
      if (response) {
        setErrorMessage(() => response);
      } else {
        setSuccessMessage(
          () =>
            `Thanks ${state.student_name} for showing interest to take admission. We will get back to you within 24 hours.`
        );
        setState(() => ({ ...initialFormState }));
        document.getElementById("student_age").value = "";
      }
    } else {
      setErrorMessage(() => error);
    }
    setIsLoading(() => false);
  };

  const handelInput = (fieldName) => (event) => {
    setErrorMessage(() => "");
    setSuccessMessage(() => "");
    setState((_state) => ({ ..._state, [fieldName]: event?.target?.value }));
  };

  return (
    <div className="admission_form_container">
      <div className="admissionForm mt-5 mb-5">
        <div className="admission_enquary_form">
          <h1>Admission Enquiry Form</h1>
        </div>
        <form action="" onSubmit={handelSubmit}>
          <div className="form_fields flex-column flex-lg-row">
            <label htmlFor="student_name" className="block mb-2 mb-lg-0">
              Student's Name: <span className="required">*</span>
            </label>
            <input
              type="text"
              autoComplete="off"
              id="student_name"
              value={state.student_name}
              onChange={handelInput("student_name")}
              required
              disabled={isLoading}
            />
          </div>
          <div className="form_fields flex-column flex-lg-row">
            <label htmlFor="student_age" className="block mb-2 mb-lg-0">
              Age: <span className="required">*</span>
            </label>
            <input
              type="number"
              autoComplete="off"
              id="student_age"
              value={state.age}
              onChange={handelInput("age")}
              disabled={isLoading}
              required
            />
          </div>
          <div className="form_fields flex-column flex-lg-row">
            <label
              htmlFor="student_previous_school"
              className="block mb-2 mb-lg-0"
            >
              Previous School(if any):
            </label>
            <input
              type="text"
              autoComplete="off"
              value={state.previous_school}
              id="student_previous_school"
              onChange={handelInput("previous_school")}
              disabled={isLoading}
            />
          </div>
          <div className="form_fields flex-column flex-lg-row">
            <label
              htmlFor="student_contact_number"
              className="block mb-2 mb-lg-0"
            >
              Contact Number: <span className="required">*</span>
            </label>
            <input
              type="number"
              autoComplete="off"
              value={state.contact_number}
              id="student_contact_number"
              onChange={handelInput("contact_number")}
              required
              disabled={isLoading}
            />
          </div>
          <div className="form_fields flex-column flex-lg-row">
            <label htmlFor="address" className="block mb-2 mb-lg-0">
              Current Address:
            </label>
            <textarea
              rows="3"
              id="student_address"
              value={state.address}
              onChange={handelInput("address")}
              disabled={isLoading}
            />
          </div>
          {errorMessage !== "" && (
            <div
              className="error_message"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          )}
          {!errorMessage && successMessage && (
            <div className="admission_success_message">{successMessage}</div>
          )}
          <div className="submit_button">
            <input type="submit" id="submit_btn" disabled={isLoading} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdmissionForm;
