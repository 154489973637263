import React from 'react';
import HeroImage from '../../components/HeroImage';
import Heading from '../../components/Heading';
import Capsule from '../../components/Capsule';
import SoftSkill from '../../images/softskill.png'
import '../../styles/CoCurriculum/Softskills.css'
const SoftSkills = () =>{
    const lines = ["Develops life skills like Communication & Interpersonal skills",
        "Decision making and critical thinking.",
        "Self-management.",
        "Leadership qualities.",
        "Language empowerment",]
    return (
        <>
        <HeroImage/>
        <div className="container softSkillsContainermargin">
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <Heading title={"Soft Skills"} description={"Chatterjee Noble School gives global edge to every student through International Olympiads"} colHeading={"col-lg-2 col-md-2"} colDescription={"col-lg-10 col-md-10"}/>
                    {
                        lines.map(line=><Capsule name={line}/>)
                    }
                </div>
                <div className="col-lg-4 col-md-4 softskillImage">
                    <img src={SoftSkill}/>
                </div>
                
            </div>
        </div>
        </>
    )
}

export default SoftSkills;
