import React from 'react'
import './LatestEvents.css'
import LatestEventCards from './LatestEventCards'
import event_image from '../../images/events.jpg'
import gallery1 from "../../images/gallery3.png"
import gallery2 from "../../images/gallery2.png"
import gallery3 from "../../images/gallery1.png"


const gallery_data = [
  {img:gallery1,heading:"CBSE Fareast Zone Archery Competition",text:"Chatterjee Noble School is the first CBSE affiliated School in Nadia to Organize..."},
  {img:gallery2,heading:"Remembering the timeless contributions of Kabiguru Rabindranath Tagore",text:"Chatterjee Noble School is paying tribute to Kabiguru Rabindranath Tagore’s enduring influence on the occasion of his death anniversary."},
  {img:gallery3,heading:"Teachers day celebration at Chatterjee Noble School",text:"Teachers' Day celebrates the dedication and contributions of educators, expressing gratitude... "},

]
const LatestEvents = () => {
  return (
    <div className="latest-events-container">
        <div className="latest-events-heading">
            Latest Heading
        </div>
        <div className="latest-events-row" >
        <LatestEventCards url={gallery_data[0].img} heading={gallery_data[0].heading} text={gallery_data[0].text}/>
        <LatestEventCards url={gallery_data[1].img} heading={gallery_data[1].heading} text={gallery_data[1].text}/>
        <LatestEventCards url={gallery_data[2].img} heading={gallery_data[2].heading} text={gallery_data[2].text}/>
        </div>
        <div className="latest-events-row" id='latest-event-hide'>
        <LatestEventCards/>
        <LatestEventCards/>
        <LatestEventCards/>
        </div>
    </div>
  )
}

export default LatestEvents