import React, { useEffect, useState } from 'react';
import HeroImage from '../../components/HeroImage';
import ArtGallery from '../../components/ArtGallery';
import TableComponent from '../../components/TableComponent';
import Heading from '../../components/Heading';
import TitleComponent from '../../components/TitleComponent';
import '../../styles/Administration/Faculty.css';
import { getRestClient } from '../../services';
import { readItems } from '@directus/sdk';

const TABLE_HEADINGS = ['SL.No.', 'Name', 'Designation', 'Qualification'];

const Faculty = () => {
  const faculties = [
    {"NAME": "DR GUNJA DAS", "DESIGNATION": "PRINCIPAL", "QUALIFICATION": "P.hd.,B.Ed."},
    {"NAME": "BANHI SENGUPTA", "DESIGNATION": "VICE PRINCIPAL", "QUALIFICATION": "B.ED"},
    {"NAME": "NILANJANA CHAKRABORTY", "DESIGNATION": "HM", "QUALIFICATION": "B.Ed."},
    {"NAME": "AMIT LAL", "DESIGNATION": "PGT", "QUALIFICATION": "B.ED"},
    {"NAME": "SHILPI ROY BHATTACHARJEE", "DESIGNATION": "TGT", "QUALIFICATION": "B.ED"},
    {"NAME": "NABANITA DEY", "DESIGNATION": "TGT", "QUALIFICATION": "B.ED"},
    {"NAME": "SUSHMITA PAL", "DESIGNATION": "TGT", "QUALIFICATION": "B.ED"},
    {"NAME": "SUKALPA SEN", "DESIGNATION": "PGT", "QUALIFICATION": "B.ED"},
    {"NAME": "Rahul kundu", "DESIGNATION": "PRT", "QUALIFICATION": "MFA"},
    {"NAME": "NILANJAN MONDAL", "DESIGNATION": "PGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "GUDIYA MISHRA", "DESIGNATION": "TGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "ANKITA BISWAS", "DESIGNATION": "PRT", "QUALIFICATION": "B.Ed."},
    {"NAME": "Megha Mazumdar", "DESIGNATION": "PRT", "QUALIFICATION": "B.Ed."},
    {"NAME": "ANUBRATA RAY", "DESIGNATION": "TGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "SOMA SAHA BISWAS", "DESIGNATION": "TGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "Pabitra Prasad Guha", "DESIGNATION": "PGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "AYAN KUMAR MAITRA", "DESIGNATION": "PRT", "QUALIFICATION": "D.L.Ed."},
    {"NAME": "Isha Sadhukhan", "DESIGNATION": "NTT", "QUALIFICATION": "D.L.Ed."},
    {"NAME": "Marina Joseph", "DESIGNATION": "NTT", "QUALIFICATION": "D.L.Ed."},
    {"NAME": "Sanchita Barman", "DESIGNATION": "PRT", "QUALIFICATION": "D.L.Ed."},
    {"NAME": "Bulti Das Biswas", "DESIGNATION": "PGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "UTTAM SARKAR", "DESIGNATION": "PGT", "QUALIFICATION": "MCA,B.Ed."},
    {"NAME": "PRIYANJITA BISWAS", "DESIGNATION": "TGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "ARUNITA SADHU", "DESIGNATION": "PRT", "QUALIFICATION": "B.Ed."},
    {"NAME": "RAJESH KUMAR GHOSH", "DESIGNATION": "PGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "PUSHPITA NANDY", "DESIGNATION": "WELLNESS TEACHER", "QUALIFICATION": "DIPLOMA IN VOCATIONAL REHABILITATION"},
    {"NAME": "RITU MISHRA", "DESIGNATION": "NTT", "QUALIFICATION": "PTT"},
    {"NAME": "SUMAN DUTTA", "DESIGNATION": "SPECIAL EDUCATOR", "QUALIFICATION": "B.Ed."},
    {"NAME": "RAI DAS", "DESIGNATION": "NTT", "QUALIFICATION": "NTT"},
    {"NAME": "ELIZABETH DCRUZ", "DESIGNATION": "PRT", "QUALIFICATION": "DIPLOMA IN PAINTING"},
    {"NAME": "KHUSBU PANDAY", "DESIGNATION": "NTT", "QUALIFICATION": "MASTERS IN BHARATNATYAM"},
    {"NAME": "Subrata Das", "DESIGNATION": "TGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "PRATIM GHOSH", "DESIGNATION": "TGT", "QUALIFICATION": "B.Sc. (Hons) in Computer Science, B.Ed."},
    {"NAME": "SUNIL CHAUDHARY", "DESIGNATION": "PGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "RIA CHAKRABORTY", "DESIGNATION": "PRT", "QUALIFICATION": "D.El.Ed"},
    {"NAME": "POULAMI MAJUMDER", "DESIGNATION": "TGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "CHIRASREE CHOWDHURY", "DESIGNATION": "PGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "TANAYA DAS", "DESIGNATION": "PRT", "QUALIFICATION": "D.El.Ed"},
    {"NAME": "REKHA MONDAL", "DESIGNATION": "TGT", "QUALIFICATION": "Bsc Bed"},
    {"NAME": "NILOTPAL SEN BAKSHI", "DESIGNATION": "TGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "PARBATI DUTTA SARKAR", "DESIGNATION": "TGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "LIPI SAHA", "DESIGNATION": "TGT", "QUALIFICATION": "B.Ed."},
    {"NAME": "LUPA HALDAR BISWAS", "DESIGNATION": "PGT", "QUALIFICATION": "MLIS"},
    {"NAME": "ANKITA GHOSH", "DESIGNATION": "PRT", "QUALIFICATION": "PTT"},
    {"NAME": "MANOJ GHOSH", "DESIGNATION": "PET", "QUALIFICATION": "B.P.Ed"},
    {"NAME": "JINIYA BISWAS", "DESIGNATION": "PGT", "QUALIFICATION": "B.Ed."}
]

  // const [loading, setLoading] = useState(false);
  // const [teachingStaffs, setTeachingStaffs] = useState([]);
  // const [nonTeachingStaffs, setNonTeachingStaffs] = useState([]);

  // const description = '';

  // const fetchFaculties = async () => {
  //   try {
  //     const client = getRestClient();
  //     const response = await client.request(
  //       readItems('facultie', {
  //         fields: ['id', 'name', 'designation', 'qualification', 'status', 'type'],
  //         filter: {
  //           status: 'published',
  //         },
  //       }),
  //     );

  //     setTeachingStaffs(() =>
  //       response
  //         .filter((item) => item.type === 'Teaching')
  //         .map((item, index) => [index + 1, item.name, item.designation, item.qualification]),
  //     );
  //     setNonTeachingStaffs(() =>
  //       response
  //         .filter((item) => item.type === 'Non Teaching')
  //         .map((item, index) => [index + 1, item.name, item.designation, item.qualification]),
  //     );
  //   } catch (error) {
  //     console.log('📢[Faculty.jsx:62]: error: ', error);
  //   }
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   fetchFaculties().then(() => {
  //     setLoading(false);
  //   });
  // }, []);
// the code mentioed above will be used when data will be comming from the backend
  return (
    <>
      <HeroImage />
      <div className="tw-text-center tw-text-4xl tw-mb-10 tw-mt-10" >
        Faculty
      </div>
      <table className='tw-table-auto tw-w[90%] tw-border-collapse tw-border tw-border-slate-400 tw-ml-auto tw-mr-auto'>
        <tr>
          <th className='tw-border tw-border-slate-300 tw-p-5'>Name</th>
          <th className='tw-border tw-border-slate-300 tw-p-5'>Designation</th>
          <th className='tw-border tw-border-slate-300 tw-p-5'>Qualification</th>
        </tr>
        {
          faculties.map((faculty)=>(
            <tr>
              <td className='tw-border tw-border-slate-300 tw-p-3'>{faculty.NAME}</td>
              <td className='tw-border tw-border-slate-300 tw-p-3'>{faculty.DESIGNATION}</td>
              <td className='tw-border tw-border-slate-300 tw-p-3'>{faculty.QUALIFICATION}</td>
            </tr>
          ))
        }
      </table>
      {/* <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <Heading
              title='Our Faculties'
              description={description}
              colHeading={'col-lg-4'}
              colDescription={'col-lg-8'}
            />
            <TitleComponent data={'Our Teaching Faculties'} />
            <div className='tableScroll'>
              <TableComponent
                headings={TABLE_HEADINGS}
                rows={teachingStaffs}
                className='tableShadow'
                loading={loading}
              />
            </div>

            <TitleComponent data={'Our Non-Teaching Faculties'} />
            <div className='tableScroll'>
              <TableComponent headings={TABLE_HEADINGS} rows={nonTeachingStaffs} loading={loading} />
            </div>
          </div>
          <div className='col-lg-4 col-md-12 col-sm-12'>
            <ArtGallery />
          </div>
        </div>
      </div> */}
      {/* the above code is commented out and it will be needed when we shall connect it with the backend  */}
    </>
  );
};

export default Faculty;
