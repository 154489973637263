import React from "react";
import "./Cbsegames.css";
import { Link } from "react-router-dom";
const Cbsegames = () => {
  return (
    <div className="cbse-games-container">
      <div className="cbse-games-form">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSce79y_2DPLRrPK0ZcQxC6r-3aLfjSaek4pm4wgz4vfp8yoTw/viewform?embedded=true"
          className="cbse-games-form-inner"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
      <div className="cbse-games-location">
        <Link
          to={"/pdf/annexture.pdf"}
          className="anexture"
        >
         DOWNLOAD ANNEXURE
        </Link>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.9514584419867!2d88.5636203748307!3d23.244853407997567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f8db8579f1cb37%3A0xb5f5b78e7b6e05c6!2sChatterjee%20Noble%20School!5e0!3m2!1sen!2sin!4v1724786058521!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="cbse-games-map"
        ></iframe>
      </div>
    </div>
  );
};

export default Cbsegames;
