import "../styles/NoticeBoard.css";

import NoticeBoard_img from "../images/notice_board.png";

const NoticeBoard = () => {
  return (
    <div className="noticeboard_container">
      <img
        className="notice_board_img"
        src={NoticeBoard_img}
        alt="notice_board"
      />
      <div className="row">
        <div className="notices">
          <i class="far fa-check-circle pr-1"></i>
          Your Class will Start from 11.00 pm
        </div>
        <div className="notices">
          <i class="far fa-check-circle pr-1"></i>
          Your Class will Start from 11.00 pm
        </div>
        <div className="notices">
          <i class="far fa-check-circle pr-1"></i>
          Your Class will Start from 11.00 pm
        </div>
        <div className="notices">
          <i class="far fa-check-circle pr-1"></i>
          Your Class will Start from 11.00 pm
        </div>
        <div className="notices">
          <i class="far fa-check-circle pr-1"></i>
          Your Class will Start from 11.00 pm
        </div>
      </div>
    </div>
  );
};

export default NoticeBoard;
