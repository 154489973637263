import React from 'react'
import HeroImage from '../components/HeroImage'
import ArtGallery from '../components/ArtGallery'
const ContactUs =() =>{
    return (
        <>
        <HeroImage />
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
  
            </div>
            <ArtGallery />
          </div>
        </div>
      </>
    )
}

export default ContactUs
