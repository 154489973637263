import "../../styles/Academics/Curriculmn.css";

import { useEffect, useState } from "react";

import ArtGallery from "../../components/ArtGallery";
import Capsule from "../../components/Capsule";
import Heading from "../../components/Heading";
import HeroImage from "../../components/HeroImage";
import Loader from "../../components/Loader";
import TitleComponent from "../../components/TitleComponent";
import { getPageData } from "../../services/page.service";

const Curriculum = () => {
  // const [loading, setLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});

  const SubjectNames = [
    "English",
    "Bengali",
    "Hindi",
    "Geography",
    "History",
    "SST",
    "Computer",
    "Art",
    "GK",
  ];

  const lorem =
    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde, delectus.";

  const fetchPageData = (pageName) => {
    setLoading(() => true);
    getPageData(pageName).then((data) => {
      setPageData(() => data || {});
      setLoading(() => false);
    });
  };

  useEffect(() => {
    fetchPageData("curriculum");
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <Heading
              title="Curriculumn"
              description={pageData?.content}
              colHeading={"col-lg-2"}
              colDescription={"col-lg-10"}
            />
            <div className="container_title">
              <TitleComponent data={lorem} />
              <div className="subject_container">
                <div className="curriculum_meta_data">Subjects Offered:</div>
                {SubjectNames.map((subjectName) => (
                  <Capsule name={subjectName} />
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};

export default Curriculum;
