import React from "react";
import Heading from "../../components/Heading";
import HeroImage from "../../components/HeroImage";
import PhysicsLabPicture from "../../images/phyLab_cns.jpg";
import "../../styles/CoCurriculum/Club.css";
const Club = () => {
  const aboutChemLab =
  "Chatterjee Noble School hosts a diverse range of clubs: Language, Maths, Cultural, Eco, Heritage, Science, Sports, Quiz, and Tourism. These clubs offer students engaging platforms to explore languages, excel in mathematics, celebrate culture, promote sustainability, delve into history, discover scientific wonders, enjoy sports, challenge their knowledge, and embark on exciting journeys."
  return (
    <>
      <HeroImage />
      <div className="clubContainer">
        <div className="club">
          <div className="clubHeading">
            <h1>Club</h1>
          </div>
          <div className="clubDescription">{aboutChemLab}</div>
        </div>
      </div>
      {/* cards for the club starts here */}
      <div className="container">
        <div className="club_card_container">
          <div className="row">
            {/* this is the card */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="club_card">
                <div className="club_card_img">
                  <img src={PhysicsLabPicture} alt="club_pic" />
                </div>
                <div className="club_card_description">
                  <div className="club_name">Language Club</div>
                  <span className="club_president">President:</span>
                  Pallabi Maitra Dey
                </div>
              </div>
            </div>
            {/* the card ends */}

             {/* this is the card */}
             <div className="col-lg-4 col-md-6 col-12">
              <div className="club_card">
                <div className="club_card_img">
                  <img src={PhysicsLabPicture} alt="club_pic" />
                </div>
                <div className="club_card_description">
                  <div className="club_name">Maths Club</div>
                  <span className="club_president">President:</span>
                  Poulami Majumder
                </div>
              </div>
            </div>
            {/* the card ends */}

             {/* this is the card */}
             <div className="col-lg-4 col-md-6 col-12">
              <div className="club_card">
                <div className="club_card_img">
                  <img src={PhysicsLabPicture} alt="club_pic" />
                </div>
                <div className="club_card_description">
                  <div className="club_name">Cultural Club</div>
                  <span className="club_president">President:</span>
                  Prarvati Dutta Sarkar
                </div>
              </div>
            </div>
            {/* the card ends */}

             {/* this is the card */}
             <div className="col-lg-4 col-md-6 col-12">
              <div className="club_card">
                <div className="club_card_img">
                  <img src={PhysicsLabPicture} alt="club_pic" />
                </div>
                <div className="club_card_description">
                  <div className="club_name">Eco Club</div>
                  <span className="club_president">President:</span>
                  Ankita Ghosh
                </div>
              </div>
            </div>
            {/* the card ends */}

             {/* this is the card */}
             <div className="col-lg-4 col-md-6 col-12">
              <div className="club_card">
                <div className="club_card_img">
                  <img src={PhysicsLabPicture} alt="club_pic" />
                </div>
                <div className="club_card_description">
                  <div className="club_name">Heritage Club</div>
                  <span className="club_president">President:</span>
                 Elizabeth D'cruz
                </div>
              </div>
            </div>
            {/* the card ends */}

             {/* this is the card */}
             <div className="col-lg-4 col-md-6 col-12">
              <div className="club_card">
                <div className="club_card_img">
                  <img src={PhysicsLabPicture} alt="club_pic" />
                </div>
                <div className="club_card_description">
                  <div className="club_name">Science Club</div>
                  <span className="club_president">President:</span>
                  Jiniya Biswas
                </div>
              </div>
            </div>
            {/* the card ends */}
             {/* this is the card */}
             <div className="col-lg-4 col-md-6 col-12">
              <div className="club_card">
                <div className="club_card_img">
                  <img src={PhysicsLabPicture} alt="club_pic" />
                </div>
                <div className="club_card_description">
                  <div className="club_name">Sports Club</div>
                  <span className="club_president">President:</span>
                  Manoj Ghosh
                </div>
              </div>
            </div>
            {/* the card ends */}

             {/* this is the card */}
             <div className="col-lg-4 col-md-6 col-12">
              <div className="club_card">
                <div className="club_card_img">
                  <img src={PhysicsLabPicture} alt="club_pic" />
                </div>
                <div className="club_card_description">
                  <div className="club_name">Quiz Club</div>
                  <span className="club_president">President:</span>
                  Pratim Ghosh
                </div>
              </div>
            </div>
            {/* the card ends */}

             {/* this is the card */}
             <div className="col-lg-4 col-md-6 col-12">
              <div className="club_card">
                <div className="club_card_img">
                  <img src={PhysicsLabPicture} alt="club_pic" />
                </div>
                <div className="club_card_description">
                  <div className="club_name">Tourism</div>
                  <span className="club_president">President:</span>
                  Khusbu Pandey
                </div>
              </div>
            </div>
            {/* the card ends */}
          </div>
        </div>
      </div>
      {/* cards for the club ends here */}
    </>
  );
};
export default Club;
