import React from "react";
import HeroImage from "../../components/HeroImage";
import ArtGallery from "../../components/ArtGallery";
import TableComponent from "../../components/TableComponent";
import Heading from "../../components/Heading";
const Schedule = () => {
  const nonTeachingStaffs = {
    headings: ["Admission", "Status", "	Time", "Class", "Fee"],
    rows: [
      ["Open", "10:00am - 02:00pm", "Toddler", "6,650"],
      ["Open", "10:00am - 02:00pm", "Nursery", "7,350"],
      ["Open", "10:00am - 02:00pm", "L.K.G", "8,150"],
      ["Open", "10:00am - 02:00pm", "U.K.G", "9,150"],
      ["Open", "10:00am - 02:00pm", "Class I", "9,450"],
      ["Open", "10:00am - 02:00pm", "Class II", "10,850"],
      ["Open", "10:00am - 02:00pm", "Class III", "11,450"],
      ["Open", "10:00am - 02:00pm", "Class IV", "12,200"],
      ["Open", "10:00am - 02:00pm", "Class V", "15,050"],
      ["Open", "10:00am - 02:00pm", "Class VI", "15,600"],
      ["Open", "10:00am - 02:00pm", "Class VII", "16,150"],
      ["Open", "10:00am - 02:00pm", "Class VIII", "16,750"],
    ],
  };
  const description =
    "Chatterjee Noble School have been instituted under the aegis of Chatetrjee Welfare Trust. Our Schools have a District based School Management Committee .Trustees and nominated Members, representatives of Independent Schools, Faculty Members of Chatterjee Noble School, Parents and Members of local civic administration.";
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <Heading
              title="Admission Schedule"
              colHeading={"col-lg-5"}
              colDescription={"col-lg-7"}
            />
            <TableComponent tableData={nonTeachingStaffs} />
          </div>
          <div className="col-lg-4 col-md-4">
              <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;
