import React from "react";
import "../../styles/Academics/Scholarship.css";
import school_image from "../../images/school_web.jpg";
import "../../styles/HeroImage.css";
import gal_img from '../../images/library1.jpeg';
import ArtGallery from "../../components/ArtGallery";
import Heading from "../../components/Heading";
import "../../styles/VisionMission.css";
const Scholarship = () => {
const description = ""
  const image = school_image;
  const message = "Be the part of educational revolution!";
  return (
    <>
      <div className="hero_img_container">
        <img src={image} alt="school_image" className="hero_image" />
      </div>
      <div className="hero_image_bottom_tag_line">
        <div className="moving_text">{message}</div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12">
            <Heading
              title="Betiyon ki Shiksha Scholarship"
              description={description}
              colHeading={"col-lg-4"}
              colDescription={"col-lg-8"}
            />
            {/* container starts here */}
            <div className="vmcontainer">
              {/* <div className="vmImage_container">
                <img src={"./images/vision.png"} className="vmImage" />
              </div> */}
              <div className="vmdescription">
                <div className="vmTitle">Betiyon Ki Shiksha Scholarship</div>
                In accordance with “Right to Education Act” (RTE Act) 2009 and as an initiative to promote and facilitate girl’s education specifically in rural and semi-urban areas, Chatterjee Noble School, after getting inspired by the Union Government’s comprehensive skill to address the educational needs of girls --- “Beti Bachao Beti Padhao”, has adopted an initiative of its own to generate awareness and improve the efficiency of welfare services intended for girls in terms of imparting quality education. 
              </div>
            </div>
            {/* container ends here */}
            {/* container starts here */}
            <div className="vmcontainer">
              {/* <div className="vmImage_container">
                <img src={"./images/mission.png"} className="vmImage" />
              </div> */}
              <div className="vmdescription">
                {/* <div className="vmTitle">Mission</div> */}
                The scheme, namely “Betiyon Shiksha Scholarship” programme, that aims at the upliftment of society by encouraging and facilitating holistic development of girls, has been launched on the auspicious occasion of daughter’s day.
              </div>
            </div>
            {/* container ends here */}
            {/* container starts here */}
            <div className="vmcontainer">
              {/* <div className="vmImage_container">
                <img src={"./images/motto.png"} className="vmImage" />
              </div> */}
              <div className="vmdescription">
                {/* <div className="vmTitle">Motto</div> */}
                As per the scheme undertaken by Chatterjee Noble School, free admission opportunity has been provided to girls. Preferable from underprivileged families for the time span of one month, starting from 24th September, 2022. 
              </div>
            </div>
            {/* container ends here */}
            {/* container starts here */}
            <div className="vmcontainer">
              {/* <div className="vmImage_container">
                <img src={"./images/values.png"} className="vmImage" />
              </div> */}
              <div className="vmdescription">
                {/* <div className="vmTitle">Values</div> */}
                This initiative of Chatterjee Noble School is an effort to educate citizens against  gender biasedness and to elevate the school enrollment rate of girls thus, the scheme emphasizes on the policies adopted in RTE Act ,2009.
              </div>
            </div>
            {/* container ends here */}
          </div>
          <div className="col-lg-4 col-md-4 col-12">
            <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};

export default Scholarship;
