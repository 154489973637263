import React, { useEffect, useState } from 'react';
import './NewFooter.css';
import { Link } from 'react-router-dom';
const NewFooter = () => {
  const [showPayButton, setShowPayButton] = useState(false);

  useEffect(() => {
    document.body.addEventListener('scroll', () => {
      if (document.body.scrollTop > 180) {
        setShowPayButton(true);
      } else {
        setShowPayButton(false);
      }
    });
  }, []);

  return (
    <div className='new-footer-container'>
      <a
        // href='https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=950023'
        className={`tw-fixed tw-right-5 tw-text-sm tw-z-30 tw-shadow-2xl tw-bottom-5 tw-bg-[#730e1c] tw-px-7 tw-py-2 tw-no-underline tw-border-[#730e1c] tw-ease-in-out tw-duration-300 tw-rounded-full tw-border-2 tw-font-bold hover:tw-bg-white hover:tw-text-[#730e1c] tw-text-white ${
          showPayButton ? '' : 'tw-invisible'
        }`}>
        Pay Fees Online
      </a>
      <div className='new-footer-innter-container'>
        <div className='new-footer-card'>
          <div className='new-footer-card-heading'>About Us</div>
          <div className='new-footer-content'>
            Being the leading CBSE-affiliated school in Nadia, Chatterjee Noble School, Birnagar is a part of Chatterjee
            Welfare Trust founded by Mr. Partha Kumar Chatterjee. Chatterjee Noble School, Birnagar is committed to
            providing the best quality education to its students to achieve academic excellence.
          </div>
        </div>

        <div className='new-footer-card'>
          <div className='new-footer-card-heading'>Quick Links</div>
          <div className='new-footer-content'>
            <Link to={'/pdf/mandatory-public-disclosure.pdf'} className='new-footer-links'>
              Mandatory Public Disclosure
            </Link>
            <br />
            <Link to={''} className='new-footer-links'>
              Affiliation Video - (Class X)
            </Link>
            <br />
            <Link to={''} className='new-footer-links'>
              Affiliation Video - (Class XII)
            </Link>
            <br />
            <Link to={''} className='new-footer-links'>
              Transfer Certificates
            </Link>
            <br />
            <Link to={''} className='new-footer-links'>
              Affiliation Certificate
            </Link>
          </div>
        </div>

        <div className='new-footer-card'>
          <div className='new-footer-card-heading'>Contact Info</div>
          <div className='new-footer-content'>
            <div className='new-footer-contact-links'>
              <i class='fa-duotone fa-solid fa-location-crosshairs'></i> Khardapara, Birnagar, Nadia, Pin - 741127, P.O.
              - Birnagar, Dist - Nadia
            </div>

            <div className='new-footer-contact-links'>
              <i class='fa-duotone fa-solid fa-phone'></i> 9836831662 / 7908258686
            </div>

            <div className='new-footer-contact-links'>
              <i class='fa-duotone fa-solid fa-envelope'></i> cnsbprincipal@gmail.com
            </div>

            <div className='tw-flex tw-text-3xl tw-mt-4'>
              <a href='https://www.youtube.com/@cns8738' target='_blank' rel='noreferrer' className='tw-mr-5'>
                <i class='fa-duotone fa-brands fa-youtube tw-text-white'></i>
              </a>
              <a href='https://www.facebook.com/profile.php?id=100065156064283' target='_blank' rel='noreferrer'>
                <i class='fa-duotone fa-brands fa-facebook tw-text-white'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
