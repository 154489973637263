import React from "react";
import Lib from "../../images/cnslib.png";
import "./WhyChooseCns.css";
const WhyChooseCns = () => {
  return (
    <div className="why-choose-cns-container">
      <div className="why-choose-cns-left-container">
        <div className="why-choose-cns-heading">Why CNS is the best?</div>
        <div className="why-choose-cns-text">
          At Chatterjee Noble School, Birnagar, we prioritize the right
          to learning for every child, cultivating creativity and educational
          initiatives. Our commitment revolves around making teaching and
          learning a stimulating experience, complemented by a diverse array of
          extra-curricular activities, ensuring a first-class holistic
          education.
        </div>
        <div className="why-choose-cns-bottom">
          <div className="why-choose-cns-card">
            <div className="why-choose-cns-card-heading">
              Ideal Student-Teacher Ratio
            </div>
            <div className="why-choose-cns-card-text">
            We prioritize maintaining a suitable student-teacher ratio ensuring each student receives personalized attention. This intentional approach ensures our educators can understand the unique strengths and areas of improvement for each child. Plus, with smaller class sizes, our teachers can tailor their teaching methods to suit individual learning styles and foster a supportive environment.

            </div>
          </div>
          <div className="why-choose-cns-card">
            <div className="why-choose-cns-card-heading">
              Multi-Sport Facility
            </div>
            <div className="why-choose-cns-card-text">
            In our commitment to nurture well-rounded individuals, we provide a multi-sport facility on our campus. It ensures students have the opportunity to engage in a variety of sports which can further promote an active and healthy lifestyle. Whether it’s strategic thinking and personal discipline for individual sports or team sports for collaboration, our facilities are designed to cater to a range of interests.
            </div>
          </div>
        </div>
      </div>
      <div className="why-choose-cns-right-container">
        <img id="why-cohhose-cns-image" src={Lib} alt="" />
      </div>
    </div>
  );
};

export default WhyChooseCns;
