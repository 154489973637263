import React from 'react'
import '../styles/Capsule.css'
const Capsule = (props) => {
    return (
        <div className="capsule">
              <i class="far fa-check-circle pr-1"></i>
                {props.name}
        </div>
    )
}

export default Capsule
