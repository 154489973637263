import React from 'react';
import './NewAdmissionForm.css';
import { createItem } from '@directus/sdk';
import { getRestClient } from '../../services/index';

const NewAdmissionForm = () => {
  const onSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    const name = e.target[0].value;
    const age = e.target[1].value;
    const present_classs = e.target[2].value;
    const phone_number = e.target[3].value;
    const whatsapp_number = e.target[4].value;

    try {
      const client = getRestClient();
      const result = await client.request(
        createItem('admission', {
          name,
          age,
          present_classs,
          phone_number,
          whatsapp_number,
        }),
      );
      alert('Admission form submitted successfully');
      e.target.reset();
    } catch (error) {
      console.log('🚀 ~ file: NewAdmissionForm.jsx ~ line 39 ~ onSubmit ~ error', error);
    }
  };

  return (
    <div className='new_admission_form_container'>
      <div id='admission_form_heading'>ADMISSIONS ARE OPEN (Session 2024-2025)</div>
      <form onSubmit={onSubmit}>
        <input className='new_admission_form_input' type='text' placeholder='ENTER YOUR NAME' />
        <input className='new_admission_form_input' type='text' placeholder='ENTER YOUR AGE' />
        <input className='new_admission_form_input' type='text' placeholder='ENTER THE PRESENT CLASS' />
        <input className='new_admission_form_input' type='text' placeholder='ENTER YOUR CONTACT NUMBER' />
        <input className='new_admission_form_input' type='text' placeholder='ENTER YOUR WHATSAPP NUMBER' />
        <input id='new_admission_form_id' type='submit' value={'SUBMIT NOW'} />
      </form>
    </div>
  );
};

export default NewAdmissionForm;
