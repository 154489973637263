import React from 'react'
import Heading from "../../components/Heading";
import HeroImage from "../../components/HeroImage";
import ChemistryLabPic from "../../images/chem_final_1.webp";
import "../../styles/Facilites/ChemistryLab.css";
const SmartRoom = () => {
    const aboutChemLab = "Our School is providing well-equipped instruments in physics lab where experiments are carried out. A physics lab aids a student in establishing the relevance of the theories read in books. It brings clarity in the mind of the students and put a fundamental slab for them.";
    return (
      <>
        <HeroImage />
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col">
              <Heading
                title={"Chemistry Lab"}
                description={""}
                colHeading={"col-lg-3 col-md-3"}
                colDescription={"col-lg-9 col-md-9"}
              />
              <div className="aboutText">{aboutChemLab}</div>
            </div>
            <div className="col-lg-4 col-md-4 col">
              <img
                src={ChemistryLabPic}
                width="400px"
                heigh="400px"
                className="ChemistryLabImage"
              />
            </div>
          </div>
        </div>
      </>
    );
}

export default SmartRoom;
