import React, { useState } from "react";
import Carousel_0 from "react-bootstrap/Carousel";
import Carousel_1 from "../images/school_web.jpg";
import Carpusel_2 from "../images/Chatterjee Noble School.jpg";
import Carpusel_3 from "../images/onlineEvent.png";
import "../styles/ArtGallery.css";

const ArtGallery = () => {
  const [img_url, set_img_url] = useState("./images/school_web.jpg");
  const [img_id, setImageId] = useState(0);
  const images = [
    "./images/school_web.jpg",
    "./images/Chatterjee Noble School.jpg",
    "./images/onlineEvent.png",
  ];
  const getNextImage = () => {
    const totalImages = images.length;
    if (img_id + 1 == totalImages) {
      setImageId(0);
      set_img_url(images[0]);
    } else {
      setImageId(img_id + 1);
      set_img_url(images[img_id + 1]);
    }
  };
  const getPreviousImage = () => {
    const totalImages = images.length;
    if (img_id > 0) {
      setImageId(img_id - 1);
      set_img_url(images[img_id - 1]);
    } else {
      setImageId(totalImages - 1);
      set_img_url(images[totalImages - 1]);
    }
  };
  return (
    <>
      {/* <div className="col-lg-4"> */}
        <div className="artgallery">
          <div className="art_heading">
            <div className="row">
              <div className="col-5">
                <div className="art_text">Art Gallery</div>
              </div>

              <div className="col-7">
                <div className="straight_line"></div>
                <div className="btn-container">
                  <div className="btn btn-primary" onClick={getPreviousImage}>
                    <i className="fas fa-arrow-circle-left"></i>
                  </div>
                  <div className="btn btn-primary ms-1" onClick={getNextImage}>
                    <i className="fas fa-arrow-circle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="image_slider">
            <img src={img_url} alt="image" className="art_img" />
          </div>
        </div>
      {/* </div> */}
    </>
  );
};
export default ArtGallery;
