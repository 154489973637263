import React from "react";
import '../../styles/Academics/Ebooks.css'

const Ebooks = ()=> {
  return (
   <div className="EbooksCenter">
    <a href="https://www.cbse.gov.in/ecbse/welcome.html">For Ebooks Click This Link</a>
   </div>
  )
}

export default Ebooks