import React from 'react';
import ArtGallery from '../components/ArtGallery';
import HeroImage from '../components/HeroImage';
import Heading from '../components/Heading';
import '../styles/Calender.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const SchoolCalendar = () => {
  return (
    <>
      <HeroImage />
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <Heading
              title='Academic Calender 2022-23'
              description={''}
              colHeading={'col-lg-6'}
              colDescription={'col-lg-6'}
            />
            <div className='calender_container'>
              <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.js'>
                <div className='calender_body'>
                  <Viewer fileUrl={`${process.env.PUBLIC_URL}/pdf/calender.pdf`} />
                </div>
              </Worker>
            </div>
          </div>
          <div className='col-lg-4'>
            <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};
export default SchoolCalendar;
