import React from "react";
import NoticeBoard from "../../components/NoticeBoard";
import HeroImage from "../../components/HeroImage";
import Heading from "../../components/Heading";
const Abacus = () => {
  const description =
    "The primary motive of introducing abacus in school curriculam is to enhance the student's ability to solve mathametical calculations rapidly. It helps to improve precision , accuracy and concentration within students and enables them to solve logical problems with ease.";
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-8">
            <Heading
              title="Abacus"
              colHeading="col-lg-2 col-md-2"
              description={description}
              colDescription="col-lg-10 col-md-10"
            />
          </div>
          <div className="col-md-4 col-lg-4">
            <NoticeBoard />
          </div>
        </div>
      </div>

    </>
  );
};

export default Abacus;
