import React from "react";
import school_image from "../images/school_web.jpg"
import "../styles/HeroImage.css"
const HeroImage = ()=>{
    const image = school_image;
    const message = "Be the part of educational revolution!";
    return(
        <>
        <div className="hero_img_container">
            <img src={image} alt="school_image" className="hero_image" />
        </div>
        <div className="hero_image_bottom_tag_line">
            <div className="moving_text">
                {message}
            </div>
        </div>
        </>
    )
}
export default HeroImage;
