import React from "react";
import NoticeBoard from "../../components/NoticeBoard";
import HeroImage from "../../components/HeroImage";
import Heading from "../../components/Heading";
const FeepaymentRules = () => {
  const Desctiption =
    "School fees are payable on monthly basis. School fees are collected between 9:30am to 3:30pm from Monday to Saturday. School fees can be deposited through online link that is www.chatterjeenobleschool.com. School fees has to be deposited within 15th of every month failing which a lift fine of Rs.100 for tuition fees and Rs.50 for transportation fees should be paid. The school office remains open from 8:30am to 2pm on all working days. Fees card will be provided to each student at the time of admission of the session. People who have outstanding fees for more than three months will have their name stuck off and readmission may be considered only on payment of readmission charge and clearing all these dues.";
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12">
            <Heading
              title="Fee Payment Rule"
              colHeading="col-lg-4"
              colDescription="col-lg-8"
              description={Desctiption}
            />
          </div>
          <div className="col-lg-4 col-md-8 col-12">
            <NoticeBoard />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeepaymentRules;
