import React from 'react'
import './LatestEvents.css'
import event_image from '../../images/events.jpg'
const LatestEventCards = ({url,heading,text}) => {
  return (
    <div className='latest-events-card'>
        <div className="latest-events-image-container">
            <img width={340} src={url} alt="" />
        </div>
        <div className="latest-events-title">
       {heading}
        </div>
        <div className="latest-events-description">
          {text}
        </div>
    </div>
  )
}

export default LatestEventCards