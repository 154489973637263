import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NavigtionLink.css';

const NavigationLink = ({ nav, onClick }) => {
  const onClickCloseMenu = () => {
    if (typeof onClick === 'function') {
      onClick();
    }

    const closeMenuLabels = document.querySelectorAll('label.dropdown_list_toggler');
    closeMenuLabels.forEach((label) => {
      if (label.computedStyleMap().get('display').value === 'block') {
        label.click();
      }
    });
  };

  return (
    <>
      {nav.map((navLink) => {
        return (
          <Link className='navigationLink' onClick={onClickCloseMenu} to={navLink.link}>
            <p>{navLink.name}</p>
          </Link>
        );
      })}
    </>
  );
};
export default NavigationLink;
