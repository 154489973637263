import "../../styles/CoCurriculum/House.css";

import Heading from "../../components/Heading";
import HeroImage from "../../components/HeroImage";
import PhysicsLabPicture from "../../images/phyLab_cns.jpg";
import BlueHOuse from '../../images/teresahouse.jpeg';
import PurpleHouse from '../../images/purple_house.jpeg';
import GreenHouse from '../../images/green_house.jpeg';
import RedHouse from '../../images/red_house.jpeg';
import React from "react";

const House = () => {
  const houseDescription = "";
  const aboutChemLab =
    "Step into a world of camaraderie and friendly competition within our school houses. Each house embodies a unique spirit, fostering a sense of belonging and unity. Engage in thrilling inter-house competitions, showcase your talents, and earn points for your team. From sports tournaments to academic challenges, our houses provide a platform to showcase your skills and develop leadership qualities. Forge lasting friendships and create cherished memories as you support and cheer for your house. Embrace the vibrant atmosphere, and together, let's celebrate teamwork, resilience, and a true sense of school spirit!"
  return (
    <div>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7 col-12">
            <Heading
              title={"House"}
              description={""}
              colHeading={"col-lg-3 col-md-3"}
              colDescription={"col-lg-9 col-md-9"}
            />
            <div className="aboutText">{aboutChemLab}</div>
          </div>
          <div className="col-lg-4 col-md-5 col-12 pt-md-5">
            <img
              src={BlueHOuse}
              width="400"
              heigh="400"
              alt="cns-chem-lab"
              className="ChemistryLabImage w-100 h-auto"
            />
          </div>
        </div>
      </div>
      {/* from here the main code for houses starts */}
      <div className="container houssesContainer">
        <div className="row">
          {/* this card is for houseses */}
          <div className="col-lg-4 col-md-6 col-12">
            <div className="houseCard">
              <div className="img w-100">
                <img
                  src={BlueHOuse}
                  width="300"
                  heigh="300"
                  className="img-fluid w-100"
                  alt="cns-chem-lab"
                />
              </div>
              <div className="houseTextSection">
                <div className="row">
                  <div className="col-12">
                    <div className="houseTeacher">House Master: </div>
                    <div className="nameHouse">Pratim Ghosh</div>
                  </div>

                  <div className="col-12">
                    <div className="captain">Captain:</div>
                    <div className="nameHouse">Sayan Chakraborty</div>
                  </div>
                  <div className="col-12">
                    <div className="viceCaptain">Flag Bearer:</div>
                    <div className="nameHouse">Dona Sarkar</div>
                  </div>
                  <div className="col-12">
                    <div className="houseEvents">Events:</div>
                    Cricket, Football, Basketball, KhoKho
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* This card ends here */}
          {/* this card is for houseses */}
          <div className="col-lg-4 col-md-6 col-12">
            <div className="houseCard">
              <div className="img w-100">
                <img
                  src={PurpleHouse}
                  width="300"
                  heigh="300"
                  className="img-fluid w-100"
                  alt="cns-chem-lab"
                />
              </div>
              <div className="houseTextSection">
                <div className="row">
                  <div className="col-12">
                    <div className="houseTeacher">House Teacher: </div>
                    <div className="nameHouse">Pallabi Maitra Dey</div>
                  </div>

                  <div className="col-12">
                    <div className="captain">Captain:</div>
                    <div className="nameHouse">Debayan Roy</div>
                  </div>
                  <div className="col-12">
                    <div className="viceCaptain">Flag Berarer</div>
                    <div className="nameHouse">Anushka Halder</div>
                  </div>
                  <div className="col-12">
                    <div className="houseEvents">Events:</div>
                    Cricket, Football, Basketball, KhoKho
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* This card ends here */}

          {/* this card is for houseses */}
          <div className="col-lg-4 col-md-6 col-12">
            <div className="houseCard">
              <div className="img w-100">
                <img
                  src={RedHouse}
                  width="300"
                  heigh="300"
                  className="img-fluid w-100"
                  alt="cns-chem-lab"
                />
              </div>
              <div className="houseTextSection">
                <div className="row">
                  <div className="col-12">
                    <div className="houseTeacher">House Master: </div>
                    <div className="nameHouse">Jiniys Biswas</div>
                  </div>

                  <div className="col-12">
                    <div className="captain">Captain:</div>
                    <div className="nameHouse">Arnav Biswas</div>
                  </div>
                  <div className="col-12">
                    <div className="viceCaptain">Flag Bearer:</div>
                    <div className="nameHouse">Ankita Halder</div>
                  </div>
                  <div className="col-12">
                    <div className="houseEvents">Events:</div>
                    Cricket, Football, Basketball, Kho Kho
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* This card ends here */}

          {/* this card is for houseses */}
          <div className="col-lg-4 col-md-6 col-12">
            <div className="houseCard">
              <div className="img w-100">
                <img
                  src={GreenHouse}
                  width="300"
                  heigh="300"
                  className="img-fluid w-100"
                  alt="cns-chem-lab"
                />
              </div>
              <div className="houseTextSection">
                <div className="row">
                  <div className="col-12">
                    <div className="houseTeacher">House Teacher: </div>
                    <div className="nameHouse">Rima Jospina Rozario</div>
                  </div>

                  <div className="col-12">
                    <div className="captain">Captain:</div>
                    <div className="nameHouse">Swarnadip Paul</div>
                  </div>
                  <div className="col-12">
                    <div className="viceCaptain">Flag Bearer:</div>
                    <div className="nameHouse">Soumita Roy</div>
                  </div>
                  <div className="col-12">
                    <div className="houseEvents">Events:</div>
                    Cricket, Football, Basketball, Kho Kho
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* This card ends here */}
          
        </div>
      </div>     
    </div>
  );
};

export default House;
