import React from "react";
import HeroImage from "../../components/HeroImage";
import "../../styles/Academics/Booklist.css";
const Booklist = () => {
  const booklist = [
    { id: "1", class: "LKG", bookLink: "asgdghgh" },
    { id: "2", class: "UKG", bookLink: "bdhh" },
    { id: "3", class: "Class 1", bookLink: "gsdghf0" },
    { id: "4", class: "Class 1", bookLink: "gsdghf0" },
    { id: "5", class: "Class 1", bookLink: "gsdghf0" },
  ];
  return (
    <>
    <HeroImage/>
      <div className="row container">
        <table className="tableComponent">
          <tr className="table_component_tr">
            <th className="table_component_th">Serial Number</th>
            <th className="table_component_th">Class</th>
            <th className="table_component_th">Link</th>
          </tr>
          {booklist.map((book) => (
            <tr className="table_component_tr">
              <td className="table_component_td">{book.id}</td>
              <td className="table_component_td">{book.class}</td>
              <td className="table_component_td"><a className="table_link_booklist" href={book.bookLink}>Click Here To Download <i class="fa-solid fa-cloud-arrow-down"></i></a></td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};

export default Booklist;
