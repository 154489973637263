import React from "react";
import Heading from "../../components/Heading";
import HeroImage from "../../components/HeroImage";
import ChemistryLabPic from "../../images/chem_final_1.webp";
import "../../styles/Facilites/ChemistryLab.css";
const ChemistryLab = () => {
  const aboutChemLab =
    "Our school has a well equipped chemistry lab with most accurate digital measuring instruments required for measuring chemicals. Through this, scientific attitude is deployed in the students along with the ability to collect,compute, integrate and deduce.";
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col">
            <Heading
              title={"Chemistry Lab"}
              description={""}
              colHeading={"col-lg-3 col-md-3"}
              colDescription={"col-lg-9 col-md-9"}
            />
            <div className="aboutText">{aboutChemLab}</div>
          </div>
          <div className="col-lg-4 col-md-4 col">
            <img src={ChemistryLabPic} width="400px" heigh="400px" className="ChemistryLabImage"/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChemistryLab;
