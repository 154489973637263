import React from 'react'
import './NewHeroImage.css'
import NewAdmissionForm from '../NewAdmissionForm/NewAdmissionForm'
import { Link } from 'react-router-dom'

const NewHeroImage = () => {
  return (
    <div className='new_hero_image_container'>
      <div className="new-hero-image-left-container">
        <div className="hero-image-left-heading">
        Chatterjee Noble School – Best CBSE School in Nadia (10+2)
        </div>
        <div className="hero-image-additional">
          Link to be filled up by CBSE schools, coming "CBSE FAR EAST ZONAL GIRLS FOOTBALLTOURNAMENT 2024" <br className='hero-br' /> <br  className='hero-br'/>
          <Link className='hero-image-btn-link' to={"/cbse-games"}>Register Now</Link>
        </div>
        
      </div>
        <NewAdmissionForm/>
    </div>
  )
}

export default NewHeroImage