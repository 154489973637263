import React from "react";
import HeroImage from "../../components/HeroImage";
import ArtGallery from "../../components/ArtGallery";
import TableComponent from "../../components/TableComponent";
import Heading from "../../components/Heading";
import TitleComponent from "../../components/TitleComponent";
import '../../styles/Administration/Management.css'
const Management = () => {
  const nonTeachingStaffs = {
    headings: [
      "SL.No.",
      "Name",
      "Designation in SMC",
      "Occupation",
      "Category",
      "Phone Number",
    ],
    rows: [
      [
        "1",
        "Dr.Sajal Kumar Ganguly",
        "Chairman",
        "Docotor,BHMS",
        "Educationalist",
        "933748516",
      ],
      [
        "2",
        "Mr.Pratim Chatterjee",
        "Manager",
        "	Advocate,Kolkata High Court",
        "Trustee Representative",
        "9836831662",
      ],
      [
        "3",
        "Mr.Sayan Bhador",
        "Member",
        "Educationilist	",
        "Trustee Representative",
        "9433433659",
      ],
      [
        "4",
        "Dr.Gunja Das",
        "Member Secretary",
        "Principal,Chatterjee Noble School,Birnagar",
        "School Principal",
        "9432341829",
      ],
      [
        "5",
        "Mrs.Nilanjana Chakraborty",
        "Member",
        "Educationalist",
        "Educationalist",
        "7044076005",
      ],
      [
        "6",
        "Mrs.Moumita Dhara",
        "Member",
        "Educationalist",
        "Educationalist",
        "9830420018",
      ],
      [
        "7",
        "Dr.Ujjal Mondal",
        "Member",
        "Scientist",
        "Educationalist",
        "8902766097",
      ],
      [
        "8",
        "Mr.Subrata Chatterjee",
        "Member",
        "Principal,Krishnahagar Academics",
        "Educationalist",
        "8145740853",
      ],
      [
        "9",
        "Mr.Subhankar Chatterjee",
        "Member",
        "Principal,Malda DAV School",
        "CBSE Representative",
        "8910936145",
      ],
      [
        "10",
        "Smt.Sabia Shahin",
        "Member",
        "Principal,KV2 Kanchrapara",
        "CBSE Representative",
      ],
      [
        "11",
        "Mrs.Jiniya Biswas",
        "Member",
        "Teacher-in-Charge Ckids",
        "Teachers' Representative",
        "9832153819",
      ],
      [
        "12",
        "Mrs.Taniya Dutta Chowdhury",
        "Member",
        "Teacher-in-charge CChamps",
        "Teachers' Representative",
        "9382687464",
      ],
      [
        "13",
        "Mrs.Rupika Mondal",
        "Member",
        "Non-teaching Staff",
        "Non-teaching Staff Representative",
        "8116412666",
      ],
      [
        "14",
        "Mrs.Rama Sarkar",
        "Member",
        "Parents Representative",
        "Parents Representative",
        "9064607405",
      ],
      [
        "15",
        "Mr.Anupam Kundu",
        "Member",
        "Parents Representative",
        "Parents Representative",
        "748269819",
      ],
      [
        "16",
        "Miss.Rima Jospina Rozario",
        "Member",
        "Service,Birnagar,Nadia",
        "Councillor",
        "7478256560",
      ],
    ],
  };
  const description =
    "Chatterjee Noble School have been instituted under the aegis of Chatetrjee Welfare Trust. Our Schools have a District based School Management Committee .Trustees and nominated Members, representatives of Independent Schools, Faculty Members of Chatterjee Noble School, Parents and Members of local civic administration.";
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <Heading
              title="Management Comitee"
              description={description}
              colHeading={"col-lg-5"}
              colDescription={"col-lg-7"}
            />
            <div className="tableScroll">
            <TableComponent tableData={nonTeachingStaffs}/>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
          <ArtGallery />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Management;
