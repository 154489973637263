import style from "../styles/Loader.module.scss";

const Loader = () => {
  return (
    <div className={style.loader}>
      <div className={`${style.spinner} spinner-grow`} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
