import "../styles/Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

import AdmissionForm from "../components/AdmissionForm";
import HomeCarousel from "../components/HomeCarousel";
import ShortNews from "../components/ShortNews";
import children_frame from "../images/kinder.jpg";
import curriculum_img from "../images/curriculum.svg";
import { getNewses } from "../services/homepage.service";
import mission_img from "../images/mission.svg";
// import principal_image from "../images/principal.png";
import principal_image from "../images/principal_pic.jpeg";

import quotes from "../images/quotes.png";
import school_cartoon from "../images/school_cartoon.png";
import vision_img from "../images/vision.svg";
import SocialLinks from "../components/SocialLinks";
import NilanjanaChakrabortyImg from "../images/NilanjanaChakraborty.jpg";
import SocialMedia from "../components/SocialMedia/SocialMedia";
import NewAnnouncement from "../components/NewAnouncement/NewAnnouncement";
import NewHeroImage from "../components/NewHeroImage/NewHeroImage";
import HomePageAbout from "../components/HomePageAbout/HomePageAbout";
import WhyChooseCns from "../components/WhyChooseCns/WhyChooseCns";
import LatestEvents from "../components/LatestEvents/LatestEvents";
import Faq from "../components/Faq/Faq";
// import 'images/summercamp.jpeg'
const HomePage = () => {
  const [notices, setNotices] = useState([]);
  useEffect(() => {
    getNewses().then((_notices) => setNotices(() => _notices));
  }, []);
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <NewAnnouncement/> */}
      {/* <HomeCarousel /> */}
      <NewHeroImage/>
      {/* flash news section starts here */}
      <div className="row flash_news" id="flash_news">
        <div className="col-4 col-sm-3 flash_news_heading"> FLASH NEWS</div>
        <div className="col-8 col-sm-9 flash_news_news">
          <div className="moving_text">CBSE FAR EAST ZONE ARCHERY COMPETITION 2023-24</div>
        </div>
      </div>
      {/* flash news section ends here */}
      {/* about school section starts here */}
      {/* <div className="container">
        <div className="row Home_about_school">
          <div className="col-lg-6">
            <div className="about_school_text_area">
              <h1>
                <span className="first_about_school">WELCOME TO</span>{" "}
                <span className="second_about_school">
                  CHATTERJEE NOBLE SCHOOL
                </span>
              </h1>

              <p>
                In CNS, we embrace a child-centered education that promotes
                creative thinking and lifelong learning. Our educators are
                dedicated to inspiring children to achieve the very highest
                standards in all their endeavours as they progress in their
                educational journey with us. Through partnerships with parents,
                we strive to make every academic dream a reality.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={school_cartoon}
              alt="School_cartoone_image"
              id="school_cartoon"
            />
          </div>
        </div>
      </div> */}
      <HomePageAbout/>
      {/* about school section ends here */}
      {/* Additional data section starts here */}
      <div className="additional_section">
        <h1 className="aditional_card_heading px-3">
          Why Chatterjee Noble School?
        </h1>
        <p className="aditional_card_heading px-4">
          CNS is unique when compared to any other schools around the city. It
          stands out with <br /> the determination to think up and implement new
          ideas
        </p>
        <div className="row">
          <div className="col-lg-2 col-1"></div>
          <div className="col-lg-8 col-10">
            <div className="row aditional_section_card_container ">
              {/* individual card starts */}
              <div className="col-lg-4">
                <div className="aditional_card">
                  <div class="flip-box">
                    <div class="flip-box-inner">
                      <div class="flip-box-front">
                        <img
                          src={vision_img}
                          alt=""
                          className="aditional_card_img"
                        />
                      </div>
                      <div class="flip-box-back">
                        <img
                          src={vision_img}
                          alt=""
                          className="aditional_card_img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="aditional_card_text">
                    <h3>VISION</h3>
                    <p>
                      Our vision is to promote overall development of students
                      and to impart education in a scientifically designed...
                    </p>
                  </div>
                </div>
              </div>
              {/* individual card ends */}
              {/* individual card starts */}
              <div className="col-lg-4">
                <div className="aditional_card">
                  <div class="flip-box">
                    <div class="flip-box-inner">
                      <div class="flip-box-front">
                        <img
                          src={mission_img}
                          alt=""
                          className="aditional_card_img"
                        />
                      </div>
                      <div class="flip-box-back">
                        <img
                          src={mission_img}
                          alt=""
                          className="aditional_card_img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="aditional_card_text">
                    <h3>MISSION</h3>
                    <p>
                      To ensure favourable learning environment to students that
                      serves as a key factor to promote their holistic
                      development.
                    </p>
                  </div>
                </div>
              </div>
              {/* individual card ends */}
              {/* individual card starts */}
              <div className="col-lg-4">
                <div className="aditional_card">
                  <div class="flip-box">
                    <div class="flip-box-inner">
                      <div class="flip-box-front">
                        <img
                          src={curriculum_img}
                          alt=""
                          className="aditional_card_img"
                        />
                      </div>
                      <div class="flip-box-back">
                        <img
                          src={curriculum_img}
                          alt=""
                          className="aditional_card_img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="aditional_card_text">
                    <h3>CURRICULUM</h3>
                    <p>
                      Here at CNS we strictly follow CBSE curriculumn and Circular.
                    </p>
                  </div>
                </div>
              </div>
              {/* individual card ends */}
            </div>
          </div>
          <div className="col-lg-2 col-1"></div>
        </div>
      </div>
      {/* Additional data section ends here */}
<WhyChooseCns/>
<Faq/>
<LatestEvents/>
      {/* children image and news section starts */}
      {/* <div className="news_section">
        <div className="row news_section_container">
      
          <div className="col-12 col-md-12 col-lg-6 mb-4">
            
            <iframe width="560" height="315" src="https://www.youtube.com/embed/WOocFVl6aCE?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            
          </div>
          <div className="col-12 col-md-12 col-lg-6 ml-4 mb-4 mx-3 mx-sm-0">
            <ShortNews notices={notices} />
          </div>
         
        </div>
      </div> */}
      {/* children image and news section ends here */}
      {/* principal's desk starts here */}
      {/* <div className="desk px-4">
        <div className="principal_img_container">
          <img src={principal_image} alt="" className="principal_img" />
        </div>
        <div className="designation">Dr. Gunja Das</div>
        <div className="designation_school">Principal</div>
        <div className="row">
          <div className="col-lg-2 col-md-2"></div>
          <div className="col-lg-8 col-md-8 designation_text">
            Dear students on behalf of the entire Chatterjee Noble School
            faculty and staff, I would like to welcome you and your families.
            Education is about awakening – Awakening to the power and beauty
            that lies within all of us . To my mind education as an idea, is not
            just about bricks, mortar and concrete...
          </div>
          <div className="col-lg-2 col-md-2"></div>
        </div>
      </div> */}
      {/* principal's desk ends here */}
      {/* <SocialMedia /> */}

      {/* headmistresses desk starts here */}
      {/* <div className="headmistressDesk px-4">
        <div className="principal_img_container">
          <img src={NilanjanaChakrabortyImg} alt="" className="principal_img" />
        </div>
        <div className="designation">Nilanjana Chakraborty</div>
        <div className="designation_school">Headmistress</div>
        <div className="row">
          <div className="col-lg-2 col-md-2"></div>
          <div className="col-lg-8 col-md-8 designation_text">
            Chatterjee Noble School, is an institution that focuses on
            excellence in scholastic and Co-Scholastics aspects of the child’s
            personality.We provide a safe and positive environment where
            children can reach their maximum potential, transforming them into
            responsible citizens. Our quest is to improve and strive for
            excellence by offering a healthy array of extracurricular
            programmers.
          </div>
          <div className="col-lg-2 col-md-2"></div>
        </div>
      </div> */}
     
      {/* <AdmissionForm /> */}
      {/* Admission form ends here */}
    </>
  );
};

export default HomePage;
