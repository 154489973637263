import React from "react";
import "../styles/TitleComponent.css";
const TitleComponent = (props) => {
  return (
    <div className="title_outer_layer">
      <div className="title_section">
          {props.data}
      </div>
    </div>
  );
};

export default TitleComponent;
