import React from 'react'
import Pdf from '../../pdf/CALENDER .pdf'
const RecognitionCertificate = () => {
  return (
//     <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
//     <div >
//       <Viewer fileUrl="./pdf/calender.pdf" />
//     </div>
//   </Worker>
<>
<a href = {Pdf} target = "_blank">Download Pdf</a>
</>
  )
}

export default RecognitionCertificate