import ArtGallery from "../components/ArtGallery";
import ChemistryLabPic from "../../src/images/chem_final_1.webp";
import Heading from "../components/Heading";
import HeroImage from "../components/HeroImage";

const AboutUs = () => {
  const aboutChemLab =
    "Our School is providing well-equipped instruments in physics lab where experiments are carried out. A physics lab aids a student in establishing the relevance of the theories read in books. It brings clarity in the mind of the students and put a fundamental slab for them.";
  const description =
    "Birnagar is a small town in the district of Nadia, close to the District town of Krishnanagar. The original name of this town was known for its rich cultural heritage.The birthplace of the well-known author Rajshekhar Bose(Parsuram) was instrumental in establishing this identity. In the middle of this town, stands the Ula General Library as a mark of this continued patronage of the people towards education and culture. Around 100 years in the past, this place indeed had an English medium school with around 70,000 students and the urge for education was obvious from the fact that the British had built two identical building for Senior Cambridge curriculum. First was The Hindu College which shook the Calcutta society and shed new light on the thinking process of the people of Calcutta. The second building was Krishnanagar College that brought fresh air and new light in the age-old belief and social customs of rural life while introducing the thought of modernity in the activities of the upper crust of rural society far away from Calcutta. With this long history of culture it is needless to say that we as responsible citizens of this country, must continue our effort in keeping up with the expectations of the residents of Birnagar to provide quality education to the young generation. The school has adopted digital E-Learning mode of teaching to upgrade and modernize classroom teaching methodologies which will help students to grasp the knowledge faster and with ease. The medium of teaching followed in the school is English. Children are trained to acquire skill in speaking, reading and writing good English. Chatterjee Noble School (CNS) is our dream that finally comes alive with a facility for students, from Nursery to class X, affiliated to CBSE Board and plans to provide quality education with modern residential amenities.";
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-xl-8">
            <Heading
              title={"About Us"}
              description={description}
              colHeading={"col-lg-4 col-xl-3 col-md-3 col-4"}
              colDescription={"col-lg-8 col-xl-9 col-md-9 col-8"}
            />
          </div>
          <div className="col-lg-5 col-xl-4">
            <ArtGallery />
          </div>
        </div>
      </div>
      {/* director's message section */}
      {/* <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-12">
            <img
              src={ChemistryLabPic}
              width="400"
              heigh="400"
              className="ChemistryLabImage w-100"
            />
          </div>
          <div className="col-lg-8 col-md-8 col-12 pt-md-5">
            <div className="aboutText">{aboutChemLab}</div>
          </div>
        </div>
      </div> */}
      {/* director's message section ends here */}
    </>
  );
};
export default AboutUs;
