import React from "react";
import HeroImage from "../../components/HeroImage";
import Heading from "../../components/Heading";
import Capsule from "../../components/Capsule";
const RulesAndRegulations = () => {
  const generalRules = [
    "Clause 1: Everytime a student enters the school premises during the school hours, the student must be dressed in clean uniform, well polished shoes and proper socks.",

    "Clause2 :Cleanliness and personal hygiene are a must for each and every student.",

    "Clause 3: Every Student must carry his / her school diary and I-card everyday to school.",

    "Clause 4: Girl- students must wear skirts up to knee length.",

    "Clause 5: Girl- students must wear shirts as per the School Dress Code and must be worn, extending at least 6” below the waist.",

    "Clause 6: Girl- students with long hair should have their hair plaited or tied neatly",

    "Clause 7: Boy- students must wear shirts which should be tucked into the pants at all times.",

    "Clause 8: Boy- students must have their hair cut short and be clean - shaven, except Sikh students, who wear turbans.",

    "Clause 9: Hair of students should not be coloured, tinted or highlighted.",

    "Clause 10: Students are not permitted to wear jewellery, watches, wristbands or similar accessories.",

    "Clause 11: Any item which can cause physical harm / injury or terror or destruction to life and property should not be brought to the school by students. Knives, guns, pistols, explosives, catapults, fire crackers, gas lighters and similar items are also completely banned within the school campus.",

    "Clause 12: Possession or consumption of any drugs, alcohol or alcoholic drinks including beer or any other items of intoxication by the students are completely banned. Any violation of this policy will be subject to severe disciplinary action which may include immediate expulsion from the school.",

    "Clause 13: Mobile telephones, walkman gadgets etc. are out of bounds for students in the school premises.",

    "Clause 14: Consumption of chewing gum, pan or pan masala or similar substances by students is completely banned.",

    "Clause 15: Students are strictly forbidden to write on the walls or any part of the school premises including toilets or furniture.",

    "Clause 16: Students should not use any sort of abusive language at any time, which may lead to expulsion from the school.",

    "Clause 17: Running and playing inside the school building is not permitted.",

    "Clause 18: Students should keep the campus free of litter.",

    "Clause 19: Students must treat all school property (the building, equipment, furniture, fittings, books etc.) with care and respect and report any damage to their class teacher. Students guilty of willful damage will be punished and charged for any necessary repairs / replacements.",

    "Clause 20: Students are expected to show due courtesy to other students who are physically challenged or are much younger in age, especially in the use of common facilities.",

    "Clause 21: The school will not tolerate inappropriate behavior by students such as bullying or ragging of any kind, theft, forgery, borrowing without permission of the owner, improper care of the property of others and using obscene or vulgar language.",

    "Clause 22: Students are expected to participate in all school activities unless excused by the appropriate head on a written request from the parent / guardian.",

    "Clause 23: All students are required to attend the school on all working days. Students must attend all classes as per school Time Table and bring with them all relevant books.",

    "Clause 24: Students who are unable to attend school because of illness must bring a letter signed by their parents and show it to their class teacher on the day of their return to school. Parents are requested to attach a medical certificate from a doctor if the student is absent for more than 03 days.",

    "Clause 25: Parents should make a request for leave in advance and only when they are convinced of its absolute necessity. Except in case of injury or illness of the student, the application for leave written by the parent / guardian should be addressed to the Head of Primary / Secondary wing as applicable, and submitted through the class teacher. Leave for absence on the first and last days of summer vacation or autumn / winter break will not be ordinarily granted days.",

    "Clause 26: Students may not leave the school premises during the school working hours unless they have a letter signed by their parents, requesting permission to do so. This letter must have been sent to the Headmistress through the class teacher.",

    "Clause 27: Students suffering from a contagious or infectious disease shall not be permitted to attend the school, until a medical fitness certificate is produced.",

    "Clause 28: A student may not be allowed to sit in the session-ending examination if he has not put in a minimum of 85% of the total attendance of the academic session.",

    "Clause 29: Students who expect to return home late from school must inform their parents in advance.",

    "Clause 30: Birthdays are special occasions for the students and parents alike. Only candies or toffees should be distributed on this occasion. Parents are requested not to send any cakes or take home presents for students or teachers. On their birthday, students are free to wear clothes of their choice, subject to norms of modesty.",

    "Clause 31: Lost and found items are kept in the School Office. Any valuable item or cash found by students should be deposited at the School Office. Students are advised to clearly write their name and class on all items brought to the school.",
  ];
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <Heading
              title="Rules and Regulations"
              colHeading="col-lg-4 col-md-4"
              colDescription="col-lg-8 col-md-8"
            />{
                generalRules.map(data=><Capsule name={data}/>)
            }
            
          </div>
         
        </div>
      </div>
    </>
  );
};

export default RulesAndRegulations;
