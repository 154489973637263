import React,{useEffect,useState} from 'react';
import { getPageData } from '../services/page.service';
import Loader from '../components/Loader';
import PointDisclosure from '../pdf/16 point declaration (1).pdf'
import BuildingSeafty from '../pdf/BuildingSeaftyCirtificate.pdf'
import SchoolInfra from '../pdf/Data of school Infrastructure-converted.pdf'
import FireSeafty from '../pdf/fireSeafty.pdf'
import RecognitionCirtifiate from '../pdf/RecognitionCirtificate.pdf'
import DrinkingWater from '../pdf/Safe Drinking water & Sanitary Cndition Certificate.pdf'
import Noc from '../pdf/School NOC.pdf'
import Smc from '../pdf/Smc.pdf'
import SocityRegistration from '../pdf/SocityRegistration.pdf'
import WaterQuality from '../pdf/Water Quality Taste Report.pdf'


const Disclosure = () => {
    const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});
    const fetchPageData = (pageName) => {
        setLoading(() => true);
        getPageData(pageName).then((data) => {
          setPageData(() => data || {});
          setLoading(() => false);
        });
      };
    
      useEffect(() => {
        fetchPageData("mandatory_disclosure");
      }, []);
    
      if (loading) {
        return <Loader />;
      }
  return (
    <>
    {/* <a href = {SchoolInfra} target = "_blank">Download</a> */}
    <div dangerouslySetInnerHTML={{ __html: pageData?.content}}>

    </div>
    
    </>
  )
}

export default Disclosure