import React from 'react'

const CLassVII = () => {
  return (
    <div className="exam-container">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdAX5XNu01iQ4PQ3MCsFD10E0WnbUt2FEZwhCRwYEGs7IEtDg/viewform?embedded=true" width="80%" height="1578" frameborder="0" marginheight="0" marginwidth="0" scrolling="yes">Loading…</iframe>
    </div>
  )
}

export default CLassVII