import axios from "axios";
import { baseUrl } from ".";

export const getNewses = async (limit = 3) => {
  try {
    const response = await axios.get(
      `${baseUrl}items/notice?sort=-publish_date&limit=${limit}`
    );

    return response?.data?.data || [];
  } catch (error) {
    console.log(
      "🚀 ~ file: page.resolver.js ~ line 12 ~ getPageData ~ error",
      error
    );
    return [];
  }
};

export const getNoticeDownloadLink = ({ documentId }) => {
  return `${baseUrl}assets/${documentId}?download`;
};

export const submitAdmissionContact = async ({
  student_name,
  age,
  previous_school,
  contact_number,
  address,
}) => {
  try {
    await axios.post(`${baseUrl}items/admission_form`, {
      student_name,
      age,
      previous_school,
      contact_number,
      address,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: page.resolver.js ~ line 12 ~ getPageData ~ error",
      error
    );

    return error?.message || "Error at saving your details";
  }
};

export const getGalleryImages = async () => {
  try {
    const response = await axios.get(
      `${baseUrl}items/gallery`
    );
    
    return response?.data?.data || [];
  } catch (error) {
    console.log(
      "🚀 ~ file: page.resolver.js ~ line 12 ~ getPageData ~ error",
      error
    );
    return [];
  }
};