import React from "react";
import CoCurriculumng from "../../components/CoCurriculumnImg";
import HeroImage from "../../components/HeroImage";
import Heading from "../../components/Heading";
import "../../styles/CoCurriculum/Karate.css";
const Karate = () => {
  const description =
    "With an objective to strengtnen flexibility , balance ,agility ,concentration and co-ordination among students , Chatterjee Noble School introduced martial arts (Karate) as an integrated part of school's PE curriculam. Martial arts plays a significant role in boosting self confidence within students thereby prepairing them for any sort of adverse environment in life.";
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <Heading
              colHeading="col-lg-2 col-md-2"
              title="Karate"
              colDescription="col-lg-10 col-md-10"
              description={description}
            />
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="karateImageCard">
                  <img
                    src={"./images/school_web.jpg"}
                    className="karateImages"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="karateImageCard">
                  <img
                    src={"./images/school_web.jpg"}
                    className="karateImages"
                  />
                </div>
              </div>
              
            </div>
          </div>
          <div className="col-lg-4">
            <CoCurriculumng />
          </div>
        </div>
      </div>
    </>
  );
};

export default Karate;
