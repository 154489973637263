import React from "react";
import "../../styles/CoCurriculum/LifeSkills.css";
import softSkill from "../../images/softskill.png";
import HeroImage from "../../components/HeroImage";
import Heading from "../../components/Heading";
const LifeSkills = () => {
  const description =
    "Life skills is used to describe a set of basic skills acquired through learning and/or direct life experience that enable individuals and groups to effectively handle issues and problems commonly encountered in daily life. In everyday life, the development of life skills helps students to: Find new ways of thinking and problem solving, recognise the impact of their actions and teaches them to take responsibility for what they do rather than blame others, build confidence both in spoken skills and for group collaboration and cooperation.";
  return (
    <>
      <HeroImage />
      <div className="container lifeSkillsContainerMargin">
        <div className="row ">
          <div className="col-lg-4 col-md-4 lifeSkillImg">
            <img src={softSkill} />
          </div>
          <div className="col-lg-8 col-md-8">
            <Heading
              title={"Life Skills"}
              description={description}
              colHeading={"col-lg-2 col-md-2"}
              colDescription={"col-lg-10 col-md-10"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LifeSkills;
